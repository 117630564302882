<template>
    <div class="coctail">
        <div class="title">{{ coctail.title }}</div>
        <div class="text-center" style="padding-bottom: 15px;"><img src="../../assets/images/menu-seperator.png" alt=""></div>
        <div class="mehr row">
            <div class="col-12 col-xl-12 inhalt" v-html="coctail.desc"></div>
            <div class="col-12 col-xl-5">
                <img :src="coctail.imgSrc" :alt="coctail.title">
            </div>
            <div class="col-12 col-xl-7">
                <div class="coctail-info">
                    <div class="head">{{ lang === 'de' ? 'ZUTATEN' : 'Ingredients' }}</div>
                    <div class="text" v-html="coctail.zutaten"></div>
                </div>
                <div class="coctail-info">
                    <div class="head">{{ lang === 'de' ? 'ZUBEREITUNG' : 'Preparation' }}</div>
                    <div class="text" v-html="coctail.zubereitung"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CoctailComponent',
        data(){
          return {
              coctail: {},
              lang: 'de'
          }
        },
        mounted(){
            this.lang = this.$store.getters.getLang;
            this.coctail = this.coctailArray[this.lang];
        },
        props: [ 'coctailArray' ],
    }
</script>
