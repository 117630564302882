<template>
    <div id="about">
        <div id="about-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

                <perfect-scrollbar id="about-box-content" ref="scrollbar-text">

                    <transition name="fade" mode="out-in" :duration="0" appear >
                        <ElixirComponent
                                v-if="produkt === 'elixir'"
                                key="elixir"
                                @changeProdukt="changeProdukt">
                        </ElixirComponent>

                        <SpicedRumComponent
                                v-else-if="produkt === 'spiced'"
                                key="spiced"
                                @changeProdukt="changeProdukt">
                        </SpicedRumComponent>

                        <PineappleComponent
                                v-else-if="produkt === 'pineapple'"
                                key="spiced"
                                @changeProdukt="changeProdukt">
                        </PineappleComponent>

                        <AboutComponent
                                v-else
                                key="else"
                                @changeProdukt="changeProdukt">
                        </AboutComponent>

                    </transition>

                </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import AboutComponent from "../components/About/AboutComponent"
    import ElixirComponent from "../components/About/ElixirComponent"
    import SpicedRumComponent from "../components/About/SpicedRumComponent"
    import PineappleComponent from "../components/About/PineappleComponent"

    export default {
        metaInfo: {
            title: 'About Remedy',
        },
        data(){
          return {
              produkt: ''
          }
        },
        components: {
            AboutComponent, ElixirComponent, SpicedRumComponent, PineappleComponent
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});

            if( this.$route.query.produkt ){
                this.produkt = this.$route.query.produkt === 'elixir' ? 'elixir' : ( this.$route.query.produkt === 'spiced' ? 'spiced' : ( this.$route.query.produkt === 'pineapple' ? 'pineapple' : '' ) );
                setTimeout( () => {
                    this.$refs['scrollbar-text'].ps.update();
                }, 100);
            }

            window.addEventListener('resize', () => {
               this.$refs['scrollbar-text'].ps.update();
            })

        },
        watch: {
            // eslint-disable-next-line no-unused-vars
            $route(to, from) {

                this.$refs['scrollbar-text'].$el.scrollTop = 0;
                this.$refs['scrollbar-text'].ps.update();
                if( this.$route.query.produkt ){
                    this.produkt = this.$route.query.produkt === 'elixir' ? 'elixir' : ( this.$route.query.produkt === 'spiced' ? 'spiced' : ( this.$route.query.produkt === 'pineapple' ? 'pineapple' : '' ) );
                }else{
                    this.produkt = ''
                }
            },
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            },
            changeProdukt( name ){

                this.produkt = name;
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'About' : 'AboutENG' ), query: { 'produkt': name } });

            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>
