<template>
    <div class="row no-gutters align-items-center justify-content-center">
        <gallery :images="images" :index="index" @close="index = null"></gallery>
        <div class="col-12 col-md-10 col-xl-6">
            <div class="row no-gutters align-items-center">
                <div class="col-5 d-flex align-items-center flex-column">
                    <div
                         v-for="(image, imageIndex) in images.slice(0,3)"
                         :key="imageIndex"
                         @click="index = imageIndex"
                         class="produkt-th">
                            <div class="hoverBild"></div>
                            <img :src="`${publicPath}produkt/elixir/gallery/elixir-${imageIndex+1}-small.jpg`" alt="">
                    </div>
                </div>
                <div class="col-7 produkt-bild"><img src="../../../public/produkt/elixir/elixir.png" alt=""></div>
            </div>
        </div>
        <div class="col-12 col-xl-6 produkt-content ">
            <div class="produkt-title">
                remedy Elixir
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'HERKUNFT' : 'PLACE OF ORIGIN'}}</div>
                <div v-if="getLang === 'de'" class="text">
                    Hergestellt aus einem bis zu 7 Jahren in Eichenfässern gereiften Rum aus Panama
                </div>
                <div v-if="getLang === 'en'" class="text">
                    Made with rum from Panama that has been aged for up to seven years in oak barrels
                </div>
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'ENTSTEHUNG' : 'DATE OF ORIGIN'}}</div>
                <div class="text">
                   Anno 1923
                </div>
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'GESCHMACK' : 'FLAVOUR'}}</div>
                <div v-if="getLang === 'de'" class="text">
                    Weiche Noten von Vanille, Karamell, Orange und Honig auf der Zunge. Aromen von Walnuss, Kokosnuss und einer leichten Schärfe im Nachklang  runden diesen Rum-Likör ab.
                </div>
                <div v-if="getLang === 'en'" class="text">
                    Soft taste, with vanilla, caramel, orange, and honey seducing the palate. This rum liqueur boasts an aroma of walnut and coconut, with a slightly pungent bite of spice in the fi nish.
                </div>
            </div>
            <div class="row justify-content-between no-gutters" style="padding-top: 15px;">
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-about-remedy.png" @click="makeAboutActive"></div>
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-spicedrum.png" alt="" @click="makeSpicedActive"></div>
                <div class="col-12 text-center col-md d-none d-xl-block col-xl-5 produkt-buttons"></div>
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-pineapple.png" alt="" @click="makePineappleActive"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';
    import {mapGetters} from "vuex";

    export default {
        metaInfo: {
            title: 'Elixir',
        },
        data(){
          return {
              publicPath: process.env.BASE_URL,
              images: [
                  `../../../produkt/elixir/gallery/elixir-1-big.jpg`,
                  `../../../produkt/elixir/gallery/elixir-2-big.jpg`,
                  `../../../produkt/elixir/gallery/elixir-3-big.jpg`,
              ],
              index: null
          }
        },
        components: {
            'gallery': VueGallery
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            makeAboutActive(){ this.changeProdukt('') },
            makeSpicedActive(){ this.changeProdukt('spiced') },
            makePineappleActive(){ this.changeProdukt('pineapple') },
            changeProdukt( name ){
                this.$emit( 'changeProdukt', name );
            }
        }
    }
</script>
