<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'RemedyOComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Remedy-O',
                        imgSrc: '../../../coctails/remedyo.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl Orangensaft <br>  Orangenlimonade',
                        zubereitung: 'Remedy Spiced Rum und Orangensaft in ein Highballglas geben. Das Glas mit Eiswürfeln füllen und die Orangenlimonade dazugeben.',
                        desc: 'Als die ersten Flaschen Remedy über die Stadtgrenzen New Yorks hinaus freudige Abnehmer fanden, dauerte es nicht lange, bis ich aus diversen Ecken des Landes von neuen Cocktails und Longdrinks erfuhr. Diese recht simple Kreation hat ihren Ursprung in Florida, dem Bundesstaat, aus dem die besten Orangen des Landes stammen.'
                    },
                    en: {
                        title: 'Remedy-O',
                        imgSrc: '../../../coctails/remedyo.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl orange juice <br> Orange lemonade',
                        zubereitung: 'Pour the Remedy Spiced Rum and orange juice into a highball glass. Add ice cubes and orange lemonade to fill the glass.',
                        desc: 'When the first bottles of Remedy reached happy customers outside of New York City’s boundaries, it didn’t take long for me to hear about new recipes for cocktails and long drinks from all corners of the US. These relatively simple concoctions were mostly created in Florida – the very state where this country grows its best oranges.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
