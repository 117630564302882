<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'RemedyIceTeaComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Remedy Ice Tea',
                        imgSrc: '../../../coctails/remedyicetea.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 3 cl Limettensaft <br>  Früchtetee <br> Honig',
                        zubereitung: 'Früchtetee aufbrühen und zwei Teelöffel Honig in den heißen Tee unterrühren. Den Tee abkühlen lassen. Remedy Elixir, Limettensaft und Eiswürfel in ein Highballglas geben. Das Glas mit dem kalten Tee auffüllen.',
                        desc: 'Nichts erfrischt einen so sehr wie dieser wunderbare, fruchtige Drink. Mein guter Freund Timothy Eagleman besitzt einen kleinen Laden auf der 47th Street, in dem er exotische Tees und edle Gewürze aus verschiedenen Ländern anbietet. Als ich ihm eines Abends meinen Remedy Elixir zeigte, dauerte es nicht lange und der Remedy Ice Tea war erfunden.'
                    },
                    en: {
                        title: 'Remedy Ice Tea',
                        imgSrc: '../../../coctails/remedyicetea.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 3 cl lime juice <br> Fruit tea <br> Honey',
                        zubereitung: 'Brew the fruit tea and stir in two teaspoons of honey while its still hot. Then let the tea cool down. Pour the Remedy Elixir and lime juice into a highball glass filled with ice cubes. Then add the cold tea to fill the glass.',
                        desc: 'Hardly anything will refresh you as much as this wonderfully fruity drink. My good friend Timothy Eagleman owns a small shop on 47th Street in which he sells all sorts of exotic teas and spices from various countries. When I introduced him to my Remedy Elixir one fine evening, it didn’t take long before the Remedy Ice Tea was invented.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
