<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'BouncersChoiceComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Bouncer’s Choice',
                        imgSrc: '../../../coctails/bouncerschoice.jpg',
                        zutaten: '3 cl Remedy Spiced Rum <br> 1 cl Lime Juice Cordial',
                        zubereitung: 'Remedy Spiced Rum in ein Shotglas geben. Anschließend etwa 1 cl Lime Juice Cordial (z.B. Rose’s) hinzugeben.',
                        desc: 'In einem der bekanntesten New Yorker Clubs unserer Zeit, dem Cotton Club, regiert der Türsteher Mister Mike über Einlass oder Abweisung an den Pforten. An besonders langen Nächten bestellt er auf die Schnelle diesen kräftig fruchtigen Shot, um weiterhin entspannt und abgeklärt die Nacht zu bewältigen.'
                    },
                    en: {
                        title: 'Bouncer’s Choice',
                        imgSrc: '../../../coctails/bouncerschoice.jpg',
                        zutaten: '3 cl Remedy Spiced Rum <br> 1 cl lime juice Cordial',
                        zubereitung: 'Pour the Remedy Spiced Rum into a shot glass. Add approximately 1 cl lime juice Cordial, e.g. Rose’s.',
                        desc: 'In one of New York’s most famous clubs of our time, the Cotton Club, the bouncer Mister Mike reigned; he decided who was allowed to enter. On nights that dragged on even longer than others, he would spontaneously order this powerful, fruity shot in order to continue managing the night(life) in his characteristically relaxed and serene manner.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
