<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    // @TODO stilles
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'StillesWasserComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Stilles Wasser',
                        imgSrc: '../../../coctails/spicedbeer.jpg',
                        zutaten: '',
                        zubereitung: '-',
                        desc: '-'
                    },
                    en: {
                        title: 'Stilles Wasser',
                        imgSrc: '../../../coctails/spicedbeer.jpg',
                        zutaten: '',
                        zubereitung: '-',
                        desc: '-'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
