<template>
    <div id="about">
        <div id="about-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <perfect-scrollbar id="about-box-content" ref="scrollbar-text">

                <div class="row justify-content-center no-gutters align-items-center " style="height: 100%;">
                    <div class="col-12">
                        <div class="row justify-content-center no-gutters">
                            <div class="col-12 about-box-title text-center">{{ getLang === 'de' ? 'rEMEDY JETZT ONLINE kAUFEN!' : 'rEMEDY BUY NOW ONLINE!'}}</div>
                            <div class="col-12 abc-text " >
                                <div class="row no-gutters justify-content-center">
                                    <div class="col-12 buy-button">
                                        <h3>remedy spiced rum</h3>
                                        <a href="https://www.idealo.de/preisvergleich/MainSearchProductCategory.html?q=remedy+rum" target="_blank" title="IDEALO">
                                            <img src="../assets/images/cta-idealo.png" alt="IDEALO">
                                        </a>
                                    </div>
                                    <div class="col-12 buy-button">
                                        <h3>remedy elixir</h3>
                                        <a href="https://www.idealo.de/preisvergleich/OffersOfProduct/200232064_-remedy-elixir-34-0-7l-sierra-madre.html" target="_blank" title="IDEALO">
                                            <img src="../assets/images/cta-idealo.png" alt="IDEALO">
                                        </a>
                                    </div>
                                    <div class="col-12 buy-button"> <!-- v-if="getLang === 'de'"  -->
                                        <h3>remedy pineapple</h3>
                                        <a href="https://www.idealo.de/preisvergleich/ProductCategory/11012.html?q=Sierra+Madre+Remedy+Pineapple" target="_blank" title="IDEALO">
                                            <img src="../assets/images/cta-idealo.png" alt="IDEALO">
                                        </a>
                                    </div>
                                    <!--                            <div class="col-12 buy-button">-->
                                    <!--                                <a href="https://www.amazon.de/stores/page/0CFA4F4E-000D-4AA4-B618-8C62D569BB04?ingress=3" target="_blank" title="AMAZON">-->
                                    <!--                                    <img src="../assets/images/cta-amazon.png" alt="Amazon">-->
                                    <!--                                </a>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="col-12 buy-button">-->
                                    <!--                                <a href="https://www.rumundco.de/remedy-spiced-rum-basis-07l" target="_blank" title="Rum und CO">-->
                                    <!--                                    <img src="../assets/images/cta-rumundco.png" alt="Rum und CO">-->
                                    <!--                                </a>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="col-12 buy-button">-->
                                    <!--                                <a href="https://www.weinquelle.com/marken/Remedy.html" target="_blank" title="Weinquelle">-->
                                    <!--                                    <img src="../assets/images/cta-weinquelle.png" alt="Weinquelle">-->
                                    <!--                                </a>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="col-12 buy-button">-->
                                    <!--                                <a href="https://www.homeofmalts.com/17969/remedy-spiced-rum-41-5-vol.-0-7l" target="_blank" title="HOME OF MALTS">-->
                                    <!--                                    <img src="../assets/images/cta-homeofmalts.png" alt="HOME OF MALTS">-->
                                    <!--                                </a>-->
                                    <!--                            </div>-->
                                    <!--                            <div class="col-12 buy-button">-->
                                    <!--                                <a href="https://www.conalco.de/remedy-spiced-rum-0-7l-41-5-vol" target="_blank" title="Conalco">-->
                                    <!--                                    <img src="../assets/images/cta-conalco.png" alt="Conalco">-->
                                    <!--                                </a>-->
                                    <!--                            </div>-->
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        metaInfo: {
            title: 'ONLINE KAUFEN',
        },
        data(){
            return {
                produkt: ''
            }
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            },
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>

<style lang="less">
    .buy-button{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        margin-top: 15px;
        margin-bottom: 15px;

        transition: 0.3s all ease-in-out;

        &:hover{
            transform: scale(1.05);
        }
    }
    
    
    h3{
        font-size: 32px;
        text-align: center;
        font-family: "docktrinregular";
    }
</style>
