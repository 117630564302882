<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'ExoticRemedyComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Exotic Remedy',
                        imgSrc: '../../../coctails/exoticremedy.jpg',
                        zutaten: '6 cl Remedy Elixir <br> 2 cl Limettensaft (Saft einer halben Limette)<br>8 cl Ananassaft',
                        zubereitung: 'Remedy Elixir, Ananassaft, Limettensaft in ein Glas (Highball) geben. Das Glas mit Crushed Ice füllen und gut verrühren.',
                        desc: 'Eine wundervolle Erfindung des West Social Clubs in Long Beach, ein Ort, an dem Gäste in einer geheimen Bar vorzügliche Cocktails bestellen konnten. Der hiesige Barkeeper Paul Crocker wollte seinen Gästen in den heißen Sommermonaten einen Drink servieren, der sie entspannt die unverwechselbare Strandatmosphäre am Rande unserer Großstadt genießen lassen konnte. Well done Paul.'
                    },
                    en: {
                        title: 'Exotic Remedy',
                        imgSrc: '../../../coctails/exoticremedy.jpg',
                        zutaten: '6 cl Remedy Elixir <br> 2 cl lime juice (the juice of half a lime)<br>8 cl pineapple juice',
                        zubereitung: 'Pour the Remedy Elixir, pineapple juice and lime juice into a highball glass. Fill the glass with crushed ice and stir well.',
                        desc: 'A wonderful creation of the West Social Club in Long Beach, a place where guests had the chance to order amazing cocktails at a secret bar. Paul Crocker, working as a barkeeper at that bar, wanted to serve his guests a drink during the hot summer months that would make it easy for them to relax and soak up the unique atmosphere on the beach, just on the outskirts of this teeming city. Well done, Paul.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
