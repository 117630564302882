<template>
    <div class="row no-gutters align-items-center justify-content-center">
        <gallery :images="images" :index="index" @close="index = null"></gallery>
        <div class="col-12 col-md-10 col-xl-6">
            <div class="row no-gutters align-items-center">
                <div class="col-5 d-flex align-items-center flex-column">
                    <div
                            v-for="(image, imageIndex) in images.slice(0,3)"
                            :key="imageIndex"
                            @click="index = imageIndex"
                            class="produkt-th">
                        <div class="hoverBild"></div>
                        <img :src="`${publicPath}produkt/pineapple/gallery/pineapple-${imageIndex+1}-small.jpg`" alt="">
                    </div>
                </div>
                <div class="col-7 produkt-bild"><img src="../../../public/produkt/pineapple/pineapple.png" alt=""></div>
            </div>
        </div>
        <div class="col-12 col-xl-6 produkt-content ">
            <div class="produkt-title">
                remedy Pineapple
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'HERKUNFT' : 'PLACE OF ORIGIN'}}</div>
                <div v-if="getLang === 'de'" class="text">
                    Hergestellt aus karabischen Rums der Länder Trinidad & Tobago, Barbados und der Dominikanischen Republik.
                </div>
                <div v-if="getLang === 'en'" class="text">
                    Made with Caribbean rum from Trinidad & Tobago, Barbados, and the Dominican Republic
                </div>
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'ENTSTEHUNG' : 'DATE OF ORIGIN'}}</div>
                <div class="text">
                    Anno 1923
                </div>
            </div>
            <div class="produkt-content-info">
                <div class="head">{{ getLang === 'de' ? 'GESCHMACK' : 'FLAVOUR'}}</div>
                <div v-if="getLang === 'de'" class="text">
                    Leichte Süße und Würze mit einer sehr ausbalancierten Ananasnote die deutlich zu schmecken ist. Angenehm lander Nachklang mit einem Hauch von Vanille.
                </div>
                <div v-if="getLang === 'en'" class="text">
                    Lightly sweet and spicy, with a wonderfully balanced and prominent flavor of pineapple. A pleasantly long finish with a hint of vanilla.
                </div>
            </div>
            <div class="row justify-content-between no-gutters" style="padding-top: 15px;">
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-about-remedy.png" @click="makeAboutActive"></div>
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-elixir.png" alt="" @click="makeElixirActive"></div>
                <div class="col-12 text-center col-md d-none d-xl-block col-xl-5 produkt-buttons"></div>
                <div class="col-12 text-center col-md col-xl-5 produkt-buttons"><img src="../../assets/images/cta-spicedrum.png" alt="" @click="makeSpicedActive"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';
    import {mapGetters} from "vuex";

    export default {
        metaInfo: {
            title: 'Pineapple',
        },
        data(){
            return {
                publicPath: process.env.BASE_URL,
                images: [
                    `../../../produkt/pineapple/gallery/pineapple-1-big.jpg`,
                    `../../../produkt/pineapple/gallery/pineapple-2-big.jpg`,
                    `../../../produkt/pineapple/gallery/pineapple-3-big.jpg`,
                    `../../../produkt/pineapple/gallery/pineapple-4-big.jpg`,
                    `../../../produkt/pineapple/gallery/pineapple-5-big.jpg`,
                ],
                index: null
            }
        },
        components: {
            'gallery': VueGallery
        },
        computed: {
            ...mapGetters(['getLang'])
        },
        methods: {
            makeAboutActive(){ this.changeProdukt('') },
            makeElixirActive(){ this.changeProdukt('elixir') },
            makeSpicedActive(){ this.changeProdukt('spiced') },
            changeProdukt( name ){
                this.$emit( 'changeProdukt', name );
            }
        }
    }
</script>
