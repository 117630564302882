<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'HolyWineComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Holy Wine',
                        imgSrc: '../../../coctails/holywine.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 3 cl Roter Wermut <br>  4 cl Orangensaft',
                        zubereitung: 'Remedy Spiced Rum, Wermut und Orangensaft in einen Shaker geben, gut shaken und in ein Martini Glas abseihen.',
                        desc: 'Viele Bürger New Yorks haben in diesen Zeiten zu ihrem Glauben gefunden. Aber scheinbar nur, weil Messwein eins der wenigen legalen alkoholischen Getränke war und man diesen relativ einfach erwerben konnte. Man brauchte dazu lediglich die Erlaubnis und den Segen seines Glaubensvaters. Hier möchte ich euch meine Version eines heiligen Drinks präsentieren.'
                    },
                    en: {
                        title: 'Holy Wine',
                        imgSrc: '../../../coctails/holywine.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 3 cl red vermouth <br>  4 cl orange juice',
                        zubereitung: 'Give the Remedy Spiced Rum, vermouth and orange juice into a shaker. Shake well, then strain into a martini glass.',
                        desc: 'A lot of New Yorkers found their faith during this time - but apparently just because altar wine was one of the few legally permitted forms of alcohol and comparatively easy to obtain. One only needed the permission and the blessing of the Holy Father. I would like to introduce you to my version of a holy drink.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
