<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'SweetSourShotComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Sweet & Sour Shot',
                        imgSrc: '../../../coctails/sweetsourshot.jpg',
                        zutaten: '3 cl Remedy Elixir <br> 1 cl Lemon Squash',
                        zubereitung: 'Remedy Elixir in ein Shotglas geben. Anschließend etwa 1cl Lemon Squash (z.B. Rose’s) hinzugeben. Sweet & Sour Shot G U A R A N T E E D PR E M I UM Q U A LI T Y AP P RO V E',
                        desc: 'Wie oft sah ich verzerrte Gesichter, nachdem ein Shotglas, gefüllt mit billigem Fusel, geleert wurde. Dafür habe ich kein Verständnis. Der Genuss von guten Spirituosen soll eine angenehme, glücklich machende Angelegenheit sein. Auf diese Prämisse trinken wir diesen süßlichen, leicht sauren Shot.'
                    },
                    en: {
                        title: 'Sweet & Sour Shot',
                        imgSrc: '../../../coctails/sweetsourshot.jpg',
                        zutaten: '3 cl Remedy Elixir <br> 1 cl lemon squash',
                        zubereitung: 'Pour the Remedy Elixir into a shot glass. Add roughly 1 cl of lemon squash, e.g. Rose’s.',
                        desc: 'I can’t even count how many times I watched people make a face when they downed a shot of cheap, low-quality booze. And I still don’t understand why you would want to do that. Alcohol, in the form of fine spirits, is supposed to be a pleasure that one savours, not something vile that one downs in one go. Drinks are supposed to be fun! Let’s toast to that premise with this sweet, slightly sour shot.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
