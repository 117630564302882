<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'GingerMojitoComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Ginger Mojito',
                        imgSrc: '../../../coctails/gingermojito.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl Limettensaft <br>  1 TL Rohrzucker <br> Limettenwürfel <br> Ginger Beer<br>Minzeblätter',
                        zubereitung: 'Remedy Spiced Rum, Limettensaft, Rohrzucker, Limettenwürfel und Minzeblätter in ein Highballglas geben und mit einem Stößel etwas zerdrücken. Crushed Ice hinzugeben und mit Ginger Beer auffüllen.',
                        desc: 'Eine würzige, scharfe Abwandlung des klassischen Mojitos. Er wurde in der South Bronx entwickelt und gehörte zu den Lieblingscocktails der Mafia-Gruppe um Peter "Clutch Hand" Morello. Ziemlich harte Zeitgenossen.'
                    },
                    en: {
                        title: 'Ginger Mojito',
                        imgSrc: '../../../coctails/gingermojito.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl lime juice <br>  1 tsp cane sugar <br> Lime cubes <br> Ginger Beer<br>Mint leaves',
                        zubereitung: 'Pour the Remedy Spiced Rum, lime juice, cane sugar, lime cubes and mint leaves into a highball glass. Gently crush the ingredients with a pestle. Add crushed ice and top up the glass with ginger beer.',
                        desc: 'This is a spicier, more pungent version of the classic Mojito. It was developed in the South Bronx and used to be one of the favourite drinks of the mafia group led by Peter “Clutch Hand” Morello. Those sure were tough guys…'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
