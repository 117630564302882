<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'ElixirTonicComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Elixir Tonic',
                        imgSrc: '../../../coctails/elixirtonic.jpg',
                        zutaten: '5 cl Remedy Elixir <br> Spritzer Limettensaft <br>  Tonic Water',
                        zubereitung: 'Remedy Elixir, einen spritzer Limettensaft und Eis in ein Highballglas geben. Das Glasmit Tonic Water auffüllenund mit Limettenschnitz garnieren.',
                        desc: 'Eine Kombination aus meinem Elixir und einem Getränk, dem schon vor vielen Jahren eine heilende Wirkung nachgesagt wurde. Im 19. Jahrhundert hatten britische Soldaten in Indien schwer mit Tropenkrankheiten, wie etwa Malaria, zu kämpfen. Zur Linderung der Symptome wurde ihnen damals von den Ärzten der Armee das extrem bittere Chinin verschrieben, welches aus der Rinde des Chinarindenbaums gewonnen wird. Die Offiziere lösten das Pulver in Wasser und fügten zusätzlich Zucker hinzu. Dies war die Geburtsstunde des Tonic Waters.'
                    },
                    en: {
                        title: 'Elixir Tonic',
                        imgSrc: '../../../coctails/elixirtonic.jpg',
                        zutaten: '5 cl Remedy Elixir <br> A dash of lime juice <br>  Tonic Water',
                        zubereitung: 'Pour the Remedy Elixir and a dash of lime juice into a highball glass filled with ice. Top up with tonic water and garnish the drink with a wedge of lime.',
                        desc: 'A combination of my elixir and a beverage that for years has been rumoured to have healing powers. The British soldiers stationed in India during the 19th century had to battle tropical diseases such as malaria. To alleviate the symptoms, doctors used to prescribe the army doses of the extremely bitter quinine, which was obtained from the bark of the cinchona tree. The officers dissolved the powder in water and added sugar to make it more palatable. This was how tonic water was invented.'
                    }
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
