<template>
    <div class="row no-gutters">
<!--        <gallery ref="videoBox" @onslide="galleryOpened" :images="gallery" :index="index" @close="index = null" :options="options"></gallery>-->
        <div class="about-box-title col-12 text-center">{{ title[getLang] }}</div>
        <div class="col-12">
            <div class="row no-gutters">
                <div id="about-left" class="col-12 col-xl-8 abc-text d-flex justify-content-between flex-column" style="">
                    <div v-html="inhalt[getLang]"></div>
                    <div class="row justify-content-between" style="padding-top: 15px;">
<!--                        <div class="ab-buttons col-12 col-md-4 col-xl">-->
<!--                            <img v-if="getLang === 'de'" src="../../assets/images/cta-weiterlesen.png" alt="">-->
<!--                            <img v-if="getLang === 'en'" src="../../assets/images/cta-readmore.png" alt="">-->
<!--                        </div>-->
                        <div class="ab-buttons col-12 col-md-4 col-auto" ><img src="../../assets/images/cta-elixir.png" alt="" @click="makeElixirActive"></div>
                        <div class="ab-buttons col-12 col-md-4 col-auto"><img src="../../assets/images/cta-spicedrum.png" alt="" @click="makeSpicedActive"></div>
                        <div class="ab-buttons col-12 col-md-4 col-auto" ><img src="../../assets/images/cta-pineapple.png" alt="" @click="makePineappleActive"></div>
                    </div>
                </div>
                <div id="about-right" class="col-12 col-xl-4 d-flex flex-column justify-content-between align-items-center">
                    <div class="div">
                        <img src="../../assets/images/about-bild.jpg" alt="" style="max-width: 100%">
                    </div>
                    <div class="ab-video">
                        <img v-if="getLang === 'de'" @click="galleryOpen" src="../../assets/images/cta-video.png" alt="">
                        <img v-if="getLang === 'en'" @click="galleryOpen" src="../../assets/images/cta-video-en.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'

    export default {
        data(){
          return {
              title:{
                de: 'remedy Rum heisst dich willkommen',
                en: 'remedy Spiced Rum Welcomes You'
              },
              inhalt:{
                de: 'Die Geschichte von Remedy Rum beginnt in den 1920er Jahren in den USA. In einer Ära, die von Wohlstand, wilden und exzessiven Partys, aber auch von der Prohibition geprägt wurde. Von 1920 – 1933 galt in den USA ein landesweites Verbot für die Herstellung, den Transport und den Verkauf von Alkohol. Doch dem Reiz des Verbotenen zu widerstehen, ist alles andere als einfach... <br><br>Es wurde jede Möglichkeit genutzt, an alkoholische Getränke zu gelangen. Unter anderem wurde illegal geschmuggelter Alkohol unter medizinischen Vorwänden in Apotheken und Drogerien verkauft, sodass ein regelrechter Schwarzmarkt für Spirituosen entstand. Von dort aus gelangte auch Remedy Rum in geheime Bars und Kneipen sowie auch auf exklusive Feste, wo er die Gäste in seinen Bann zog.<br><br>Heute, fast 100 Jahre später, ist die Faszination um diesen Rum wiederentdeckt worden und so erlebt Remedy Rum seine Renaissance.',
                en: 'The history of Remedy Spiced Rum begins in the 1920s in the United States of America. In an era characterized by wealth, by wild and excessive parties, but also by the prohibition. From 1920 to 1933, it was prohibited in all of the US to produce, transport and sell alcohol. But of course, people found ways and means to get their hands on alcohol. <br><br> Drugstores and pharmacies were one possibility, as they were permitted to sell spirits on prescription. This, however, led to a whole new business line of illegally smuggled alcohol procured from pharmacies. Once Forbidden, NowReborn! This signifi ed the birth of Remedy Spiced Rum, a spirit that mesmerized guests in secret bars and pubs as well as during exclusive parties and events. <br><br> Today, almost 100 years later, we have rediscovered the fascination of this rum so that Remedy Spiced Rum is celebrating its rebirth.',
              },
              options: {
                  youTubeClickToPlay: false
              },
              gallery: [
                  {
                      title: '',
                      href: 'https://www.youtube.com/watch?v=HzBYjbSAuh8',
                      type: 'text/html',
                      youtube: 'HzBYjbSAuh8',
                      // poster: 'https://img.youtube.com/vi/hNdlUHBJDKs/maxresdefault.jpg'
                  },
              ],
          }
        },
        computed: {
          ...mapGetters(['getLang'])
        },
        methods: {
            ...mapActions(['openGallery']),
            makeElixirActive(){ this.changeProdukt('elixir') },
            makeSpicedActive(){ this.changeProdukt('spiced') },
            makePineappleActive(){ this.changeProdukt('pineapple') },
            changeProdukt( name ){
                this.$emit( 'changeProdukt', name );
            },
            galleryOpen(){
                this.openGallery({
                    items: this.gallery,
                    index: 0,
                    options: this.options,
                    autoOpenYT: true
                });

                setTimeout( () => {

                }, 250 );


               // this.$el.getElementsByClassName('video-content').click();
            }
        }
    }
</script>

<style lang="less">
    .ab-video{
        cursor: pointer;

        transition: 0.2s all ease-in-out;
        &:hover{
            transform: scale(1.07);
        }
    }
</style>
