<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'RemedyNegroniComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Remedy Negroni ',
                        imgSrc: '../../../coctails/remedynegroni.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 2 cl Campari <br>5 cl Roter Wermut',
                        zubereitung: 'Alle Zutaten in ein Glas mit großen Eiswürfeln geben und etwa 15 Sekunden gut verrühren. In das Glas eine Orangenzeste geben.',
                        desc: 'Der Geburtsort des klassischen Negronis, bestehend aus Gin, Campari und Wermut, ist die wunderschöne Stadt Florenz. Daher ist es nicht überraschend, dass der Remedy Negroni in Little Italy, New York, erfunden wurde. Einem Stadtteil, in dem es geheime Bars und Kneipen zu Haufe gab. Auf der Kenmare Street befand sich in einem großräumigen Keller unter einer Apotheke die Bar „Sawdust Inn“. Dort wurde der Remedy Negroni zum Leben erweckt und war seither ein angenehmer Begleiter durch gesellige Abende. Salute!'
                    },
                    en: {
                        title: 'Remedy Negroni ',
                        imgSrc: '../../../coctails/remedynegroni.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 2 cl Campari <br>5 cl red vermouth',
                        zubereitung: 'Pour all the ingredients into a glass with big ice cubes and stir well for roughly 15 seconds. Add an orange zest into the glass.',
                        desc: 'The original Negroni, mixed with gin, Campari and vermouth, was born in the beautiful city of Florence in Italy. So it doesn’t really come as a surprise that this Remedy Negroni version originated in New York’s Little Italy – a suburb which housed secret bars and pubs en masse. There was a huge cellar on Kenmare Street, beneath a pharmacy, which served as the location for the bar Sawdust Inn. This is where the Remedy Negroni was created to sweeten many an evening. Salute!'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
