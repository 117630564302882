<!--@TODO RWD -->
<!--@TODO haende Fehler -->
<template>
    <div id="sprechstunde" :class="{ sprechstunde_de: getLang === 'de', sprechstunde_en: getLang === 'en'}" >
        <router-link id="store-sprech-button" :class="{ english: getLang === 'en'}" :to="{ name: getLang === 'de' ? 'Store' : 'StoreENG'}"></router-link>
        <div v-if="openBox2" id="sprechstunde-box2-bg">
            <div id="sprechstunde-box2">
                <perfect-scrollbar id="sprechstunde-box-content2" ref="psContent" :watch-options="true" :options="options">
                    <div class="step">
                        <div class="title">
                            {{ getLang === 'de' ? 'Willkommen in der Sprechstunde' : 'welcome to the consultation hours' }}
                        </div>
                    </div>
                    <div class="antworten" style="text-align: center;">
                        <div v-if="getLang === 'en'">
                            <p>I'd like to ask you a few questions that will help me determine which one Remedy Drink suits you perfectly.</p>
                            <p>You can also take part in several question rounds so that I can recommend different cocktails and drinks.</p>
                            <p>Have fun!<br> Your Frank M. Farrington</p>
                        </div>
                        <div v-if="getLang === 'de'">
                            <p>Ich möchte dir gerne ein paar Fragen stellen, anhand derer ich genau feststellen kann, welcher Remedy Drink genau zu dir passt.</p>
                            <p>Du kannst auch mehrere Frage-Runden mitmachen, damit ich dir verschiedene Cocktails und Drinks empfehlen kann.</p>
                            <p>Viel Spaß <br> Dein Frank M. Farrington</p>
                        </div>
                        <img src="../assets/images/unterschreib-frank.png" alt="">
                        <p></p>
                        <div id="sprechstunde-button2" @click="openBox = true">
                            <div class="normal"></div>
                            <div class="hover"></div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </div>
        <div v-if="openBox" id="sprechstunde-box-bg">
            <div id="sprechstunde-box">
                <div id="sprechstunde-box-close" @click="openBox = false"></div>
                <perfect-scrollbar id="sprechstunde-box-content" ref="psContent" :watch-options="true" :options="options">
                    <div class="step">{{step}}/3</div>
                    <div class="step" v-if="step == 1" key="step1">
                        <div class="title">{{ fragen[getLang][indexFrage].frage }}</div>
                        <ul class="antworten">
                            <li :class="{ active: antwort_1 === 1}" @click="antwort_1 = 1">{{ fragen[getLang][indexFrage].antwort_1 }}</li>
                            <li :class="{ active: antwort_1 === 2}" @click="antwort_1 = 2">{{ fragen[getLang][indexFrage].antwort_2 }}</li>
                        </ul>
                        <div class="weiter" :class="{ 'weiter-en': getLang === 'en'}" @click="step = 2">
                            <div class="normal"></div>
                            <div class="hover"></div>
                        </div>
                    </div>

                    <div class="step" v-if="step == 2" key="step2">
                        <div class="title">{{ fragen[getLang][indexFrage][`frage_${antwort_1}`] }}</div>
                        <ul class="antworten">
                            <li :class="{ active: antwort_2 === 1}" @click="antwort_2 = 1">{{ fragen[getLang][indexFrage][`antwort_${antwort_1}_1`] }}</li>
                            <li :class="{ active: antwort_2 === 2}" @click="antwort_2 = 2">{{ fragen[getLang][indexFrage][`antwort_${antwort_1}_2`] }}</li>
                        </ul>
                        <div class="weiter" :class="{ 'weiter-en': getLang === 'en'}" @click="step = 3" key="step3">
                            <div class="normal"></div>
                            <div class="hover"></div>
                        </div>
                    </div>

                    <div class="step" v-if="step == 3">
                        <component :is="getCoctailComponent" ></component>
                        <div class="new-game-button" :class="{ 'ngb-en': getLang === 'en'}" @click="newGame">
                            <div class="normal"></div>
                            <div class="hover"></div>
                        </div>
                    </div>

                </perfect-scrollbar>
            </div>
        </div>
        <div id="sprechstunde-button" @click="openBox = true">
            <div class="normal"></div>
            <div class="hover"></div>
        </div>
    </div>
</template>

<script>

    import SpicedBeerComponent from "../components/Coctails/SpicedBeerComponent";
    import ElixirTonicComponent from "../components/Coctails/ElixirTonicComponent";
    import BitterElixirComponent from "../components/Coctails/BitterElixirComponent";
    import SmugglersJuiceComponent from "../components/Coctails/SmugglersJuiceComponent";
    import CherryKissComponent from "../components/Coctails/CherryKissComponent";
    import TheMollyPitcherComponent from "../components/Coctails/TheMollyPitcherComponent";
    import LovelyMelonComponent from "../components/Coctails/LovelyMelonComponent";
    import CitrusRemedyComponent from "../components/Coctails/CitrusRemedyComponent";
    import ExoticRemedyComponent from "../components/Coctails/ExoticRemedyComponent";
    import SweetMojitoComponent from "../components/Coctails/SweetMojitoComponent";
    import CowShoeComponent from "../components/Coctails/CowShoeComponent";
    import AppleADayComponent from "../components/Coctails/AppleADayComponent";
    import RemedyOComponent from "../components/Coctails/RemedyOComponent";
    import RemedyIceTeaComponent from "../components/Coctails/RemedyIceTeaComponent";
    import RemedyNegroniComponent from "../components/Coctails/RemedyNegroniComponent";
    import GingerMojitoComponent from "../components/Coctails/GingerMojitoComponent";
    import DarkRemedyComponent from "../components/Coctails/DarkRemedyComponent";
    import MrFayComponent from "../components/Coctails/MrFayComponent";
    import BouncersChoiceComponent from "../components/Coctails/BouncersChoiceComponent";
    import HolyWineComponent from "../components/Coctails/HolyWineComponent";
    import StillesWasserComponent from "../components/Coctails/StillesWasserComponent";
    import SweetSourShotComponent from "../components/Coctails/SweetSourShotComponent";

    import {mapGetters} from "vuex";

    export default {
        metaInfo: {
            title: 'Sprechstunde',
        },
        data(){
          return {
              options: {
                  wheelSpeed: 1
              },
              openBox: false,
              openBox2: true,
              browserWidth: 768,
              step: 1,
              indexFrage: 0,
              antwort_1: 1,
              antwort_2: 1,
              fragen: {
                  de : [
                      // FRAGE 1
                      {
                          frage: "Was isst du am liebsten?",
                          antwort_1: "Deftig",
                          antwort_2: "Leicht",
                          frage_1: "Welche Art von Gericht bevorzugst du?",
                          antwort_1_1: "Fleisch",
                          antwort_1_2: "Fisch",
                          frage_2: "Welche Art von Gericht bevorzugst du?",
                          antwort_2_1: "Gemüse",
                          antwort_2_2: "Fleisch",
                          coctail_1_1: SpicedBeerComponent,
                          coctail_1_2: ElixirTonicComponent,
                          coctail_2_1: SmugglersJuiceComponent,
                          coctail_2_2: BitterElixirComponent
                      },
                      // FRAGE 2
                      {
                          frage: "Was bevorzugst du nach einer langen Woche?",
                          antwort_1: "Entspannung",
                          antwort_2: "Feiern",
                          frage_1: "Wie übst du diese Tätigkeit aus?",
                          antwort_1_1: "Allein",
                          antwort_1_2: "Mit Freunden",
                          frage_2: "Wie übst du diese Tätigkeit aus?",
                          antwort_2_1: "Gediegen",
                          antwort_2_2: "Wild",
                          coctail_1_1: CherryKissComponent,
                          coctail_1_2: TheMollyPitcherComponent,
                          coctail_2_1: LovelyMelonComponent,
                          coctail_2_2: CitrusRemedyComponent
                      },
                      // FRAGE 3
                      {
                          frage: "Zu welcher Jahreszeit verreist du gerne? ",
                          antwort_1: "Sommer",
                          antwort_2: "Winter",
                          frage_1: "Wohin reist du am liebsten?",
                          antwort_1_1: "Strand",
                          antwort_1_2: "Stadt",
                          frage_2: "Wohin reist du am liebsten?",
                          antwort_2_1: "Berge",
                          antwort_2_2: "Küste",
                          coctail_1_1: ExoticRemedyComponent,
                          coctail_1_2: SweetMojitoComponent,
                          coctail_2_1: CowShoeComponent,
                          coctail_2_2: AppleADayComponent
                      },
                      // FRAGE 4
                      {
                          frage: "Wie würdest du dein Auftreten beschreiben?",
                          antwort_1: "Laut",
                          antwort_2: "Ruhig",
                          frage_1: "Was tust du lieber?",
                          antwort_1_1: "Tanzen",
                          antwort_1_2: "Unterhalten",
                          frage_2: "Was tust du lieber?",
                          antwort_2_1: "Zuhören",
                          antwort_2_2: "Unterhalten",
                          coctail_1_1: RemedyOComponent,
                          coctail_1_2: RemedyIceTeaComponent,
                          coctail_2_1: RemedyNegroniComponent,
                          coctail_2_2: GingerMojitoComponent
                      },
                      // FRAGE 5
                      {
                          frage: "Was trinkst du morgens lieber?",
                          antwort_1: "Kaffee",
                          antwort_2: "Tee",
                          frage_1: "Wie trinkst du dein Getränk?",
                          antwort_1_1: "Schwarz",
                          antwort_1_2: "Mit Milch",
                          frage_2: "Wie trinkst du dein Getränk?",
                          antwort_2_1: "Schwarz",
                          antwort_2_2: "Fruchtig",
                          coctail_1_1: DarkRemedyComponent,
                          coctail_1_2: GingerMojitoComponent,
                          coctail_2_1: ElixirTonicComponent,
                          coctail_2_2: RemedyIceTeaComponent
                      },
                      // FRAGE 6
                      {
                          frage: "Wohin gehst du lieber aus?",
                          antwort_1: "Kneipe",
                          antwort_2: "Cocktailbar",
                          frage_1: "Was trinkst du dort sehr gerne?",
                          antwort_1_1: "Bier",
                          antwort_1_2: "Longdrinks",
                          frage_2: "Was trinkst du dort sehr gerne?",
                          antwort_2_1: "Starke Cocktails",
                          antwort_2_2: "Fruchtige Cocktails",
                          coctail_1_1: SpicedBeerComponent,
                          coctail_1_2: ElixirTonicComponent,
                          coctail_2_1: RemedyNegroniComponent,
                          coctail_2_2: MrFayComponent
                      },
                      // FRAGE 7
                      {
                          frage: "Welche Art von Sport praktizierst du?",
                          antwort_1: "Bewegungssport",
                          antwort_2: "Kampfsport",
                          frage_1: "Etwas genauer?",
                          antwort_1_1: "Ballsport",
                          antwort_1_2: "Leichtathletik",
                          frage_2: "Etwas genauer?",
                          antwort_2_1: "Boxen",
                          antwort_2_2: "Andere Kampfkunst",
                          coctail_1_1: CitrusRemedyComponent,
                          coctail_1_2: AppleADayComponent,
                          coctail_2_1: RemedyNegroniComponent,
                          coctail_2_2: GingerMojitoComponent
                      },
                      // FRAGE 8
                      {
                          frage: "Welche Stadt würdest du gerne besuchen? New York oder Los Angeles?",
                          antwort_1: "New York",
                          antwort_2: "Los Angeles",
                          frage_1: "Was reizt dich an der Stadt?",
                          antwort_1_1: "Das Nachtleben",
                          antwort_1_2: "Die Skyline",
                          frage_2: "Was reizt dich an der Stadt?",
                          antwort_2_1: "Das Wetter",
                          antwort_2_2: "Der Glamour",
                          coctail_1_1: CitrusRemedyComponent,
                          coctail_1_2: DarkRemedyComponent,
                          coctail_2_1: SweetMojitoComponent,
                          coctail_2_2: ExoticRemedyComponent
                      },
                      // FRAGE 9
                      {
                          frage: "Bist du verheiratet oder unverheiratet?",
                          antwort_1: "Verheiratet",
                          antwort_2: "Unverheiratet",
                          frage_1: "Beschreibe mir den Status?",
                          antwort_1_1: "Glücklich verheiratet",
                          antwort_1_2: "Nur verheiratet",
                          frage_2: "Beschreibe mir den Status?",
                          antwort_2_1: "Freund/-in ",
                          antwort_2_2: "Single",
                          coctail_1_1: TheMollyPitcherComponent,
                          coctail_1_2: BouncersChoiceComponent,
                          coctail_2_1: BitterElixirComponent,
                          coctail_2_2: CherryKissComponent
                      },
                      // FRAGE 10
                      {
                          frage: "Was magst du lieber? Filme oder Musik?",
                          antwort_1: "Filme",
                          antwort_2: "Musik",
                          frage_1: "Welche Art von Musik/Film?",
                          antwort_1_1: "Liebesfilm",
                          antwort_1_2: "Actionfilm",
                          frage_2: "Welche Art von Musik/Film?",
                          antwort_2_1: "Entspannte Musik",
                          antwort_2_2: "Tanzmusik",
                          coctail_1_1: TheMollyPitcherComponent,
                          coctail_1_2: GingerMojitoComponent,
                          coctail_2_1: SmugglersJuiceComponent,
                          coctail_2_2: HolyWineComponent
                      },
                      // FRAGE 11
                      {
                          frage: "Schokolade oder Weingummi?",
                          antwort_1: "Schokolade",
                          antwort_2: "Weingummi",
                          frage_1: "Etwas genauer?",
                          antwort_1_1: "Vollmilch",
                          antwort_1_2: "Zartbitter",
                          frage_2: "Etwas genauer?",
                          antwort_2_1: "Fruchtgummi",
                          antwort_2_2: "Lakritz",
                          coctail_1_1: CowShoeComponent,
                          coctail_1_2: RemedyNegroniComponent,
                          coctail_2_1: LovelyMelonComponent,
                          coctail_2_2: SpicedBeerComponent
                      },
                      // FRAGE 12
                      {
                          frage: "Welche Früchte bevorzugst du?",
                          antwort_1: "Exotische Früchte",
                          antwort_2: "Heimische Früchte",
                          frage_1: "Welche Frucht sagt dir eher zu?",
                          antwort_1_1: "Mango",
                          antwort_1_2: "Wassermelone",
                          frage_2: "Welche Frucht sagt dir eher zu?",
                          antwort_2_1: "Apfel",
                          antwort_2_2: "Himbeere",
                          coctail_1_1: MrFayComponent,
                          coctail_1_2: LovelyMelonComponent,
                          coctail_2_1: AppleADayComponent,
                          coctail_2_2: BitterElixirComponent
                      },
                      // FRAGE 13
                      {
                          frage: "Was liest du lieber?",
                          antwort_1: "Bücher",
                          antwort_2: "Zeitung / Zeitschrift",
                          frage_1: "Welche Art von Lesestoff?",
                          antwort_1_1: "Krimi",
                          antwort_1_2: "Liebesroman",
                          frage_2: "Welche Art von Lesestoff?",
                          antwort_2_1: "Tageszeitung",
                          antwort_2_2: "Fachzeitschrift",
                          coctail_1_1: GingerMojitoComponent,
                          coctail_1_2: CherryKissComponent,
                          coctail_2_1: DarkRemedyComponent,
                          coctail_2_2: RemedyNegroniComponent
                      },
                      // FRAGE 14
                      {
                          frage: "Wie hättest du bei der Prohibitions-Frage abgestimmt?",
                          antwort_1: "Für das Alkoholverbot",
                          antwort_2: "Gegen das Alkoholverbot",
                          frage_1: "Warum?",
                          antwort_1_1: "Weil das Verbot richtig ist.",
                          antwort_1_2: "Weil ich das Verbotene Mag.",
                          frage_2: "Warum?",
                          antwort_2_1: "Der Staat hat sich nicht einzumischen.",
                          antwort_2_2: "Weil an einem guten Drink nichts Verwerfliches ist.",
                          coctail_1_1: StillesWasserComponent,
                          coctail_1_2: SmugglersJuiceComponent,
                          coctail_2_1: TheMollyPitcherComponent,
                          coctail_2_2: AppleADayComponent
                      },
                      // FRAGE 15
                      {
                          frage: "Was für Drinks machst du dir gerne zu Hause?",
                          antwort_1: "Einfache  Drinks",
                          antwort_2: "Etwas anspruchsvoller Drinks",
                          frage_1: "Wie soll dein Drink sein?",
                          antwort_1_1: "Fruchtig",
                          antwort_1_2: "Limonadig",
                          frage_2: "Wie soll dein Drink sein?",
                          antwort_2_1: "Stark",
                          antwort_2_2: "Leicht",
                          coctail_1_1: ExoticRemedyComponent,
                          coctail_1_2: CitrusRemedyComponent,
                          coctail_2_1: HolyWineComponent,
                          coctail_2_2: MrFayComponent
                      },
                      // FRAGE 16
                      {
                          frage: "Welche meiner Kreationen magst du lieber?",
                          antwort_1: "Remedy Spiced Rum",
                          antwort_2: "Remedy Elixir",
                          frage_1: "Wie soll dein Drink sein?",
                          antwort_1_1: "Fruchtig",
                          antwort_1_2: "Limonadig",
                          frage_2: "Wie soll dein Drink sein?",
                          antwort_2_1: "Fruchtig",
                          antwort_2_2: "Limonadig",
                          coctail_1_1: MrFayComponent,
                          coctail_1_2: RemedyOComponent,
                          coctail_2_1: ExoticRemedyComponent,
                          coctail_2_2: BitterElixirComponent
                      },
                      // FRAGE 17
                      {
                          frage: "Welche meiner Kreationen magst du lieber?",
                          antwort_1: "Remedy Spiced Rum",
                          antwort_2: "Remedy Elixir",
                          frage_1: "Soll dich dein Cocktail bei einem gemütlichen Abend oder einer ausgelassenen Feier begleiten?",
                          antwort_1_1: "Gemütlicher Abend",
                          antwort_1_2: "Ausgelassene Feier",
                          frage_2: "Soll dich dein Cocktail bei einem gemütlichen Abend oder einer ausgelassenen Feier begleiten?",
                          antwort_2_1: "Gemütlicher Abend",
                          antwort_2_2: "Ausgelassene Feier",
                          coctail_1_1: SweetMojitoComponent,
                          coctail_1_2: DarkRemedyComponent,
                          coctail_2_1: LovelyMelonComponent,
                          coctail_2_2: ElixirTonicComponent
                      },
                      // FRAGE 18
                      {
                          frage: "Welche meiner Kreationen magst du lieber?",
                          antwort_1: "Remedy Spiced Rum",
                          antwort_2: "Remedy Elixir",
                          frage_1: "Ist das Wetter gerade warm oder kalt?",
                          antwort_1_1: "Warm",
                          antwort_1_2: "Kalt",
                          frage_2: "Ist das Wetter gerade warm oder kalt?",
                          antwort_2_1: "Warm",
                          antwort_2_2: "Kalt",
                          coctail_1_1: MrFayComponent,
                          coctail_1_2: AppleADayComponent,
                          coctail_2_1: RemedyIceTeaComponent,
                          coctail_2_2: CowShoeComponent
                      },
                      // FRAGE 19
                      {
                          frage: "Welche meiner Kreationen magst du lieber?",
                          antwort_1: "Remedy Spiced Rum",
                          antwort_2: "Remedy Elixir",
                          frage_1: "Kreierst du den Drink für dich allein oder hast du mehrere Gäste zu verköstigen?",
                          antwort_1_1: "Für Mich",
                          antwort_1_2: "Für Gäste",
                          frage_2: "Kreierst du den Drink für dich allein oder hast du mehrere Gäste zu verköstigen?",
                          antwort_2_1: "Für Mich",
                          antwort_2_2: "Für Gäste",
                          coctail_1_1: SmugglersJuiceComponent,
                          coctail_1_2: CitrusRemedyComponent,
                          coctail_2_1: TheMollyPitcherComponent,
                          coctail_2_2: ExoticRemedyComponent
                      },
                      // FRAGE 20
                      {
                          frage: "Welche andere Spirituose außer Rum magst du gerne?",
                          antwort_1: "Gin",
                          antwort_2: "Whisky",
                          frage_1: "Welche Art von Drinks trinkst du bei dieser Kategorie gerne?",
                          antwort_1_1: "Klassischer Drink",
                          antwort_1_2: "Longdrink",
                          frage_2: "Welche Art von Drinks trinkst du bei dieser Kategorie gerne?",
                          antwort_2_1: "Longdrink",
                          antwort_2_2: "Shots",
                          coctail_1_1: RemedyNegroniComponent,
                          coctail_1_2: ElixirTonicComponent,
                          coctail_2_1: DarkRemedyComponent,
                          coctail_2_2: SweetSourShotComponent
                      },
                      // FRAGE 21
                      {
                          frage: "Welche meiner Kreationen magst du lieber?",
                          antwort_1: "Remedy Spiced Rum",
                          antwort_2: "Remedy Elixir",
                          frage_1: "Würdest du in meiner Heimat New York eher an der Upper Eastside, bei den reichen und schönen oder in Harlem, bei den kreativen Künstlern leben wollen?",
                          antwort_1_1: "Upper East Side",
                          antwort_1_2: "Harlem",
                          frage_2: "Würdest du in meiner Heimat New York eher an der Upper Eastside, bei den reichen und schönen oder in Harlem, bei den kreativen Künstlern leben wollen?",
                          antwort_2_1: "Upper East Side",
                          antwort_2_2: "Harlem",
                          coctail_1_1: MrFayComponent,
                          coctail_1_2: BouncersChoiceComponent,
                          coctail_2_1: ExoticRemedyComponent,
                          coctail_2_2: BitterElixirComponent
                      },
                  ],
                  en: [
                      // FRAGE 1
                      {
                          frage: "How do you like to eat?",
                          antwort_1: "hearty",
                          antwort_2: "light",
                          frage_1: "Which type of meal do you prefer?",
                          antwort_1_1: "meat",
                          antwort_1_2: "fish",
                          frage_2: "Which type of meal do you prefer?",
                          antwort_2_1: "vegetables",
                          antwort_2_2: "meat",
                          coctail_1_1: SpicedBeerComponent,
                          coctail_1_2: ElixirTonicComponent,
                          coctail_2_1: SmugglersJuiceComponent,
                          coctail_2_2: BitterElixirComponent
                      },
                      // FRAGE 2
                      {
                          frage: "What do you like to do after a long week?",
                          antwort_1: "relax",
                          antwort_2: "party",
                          frage_1: "How do you go about this?",
                          antwort_1_1: "alone",
                          antwort_1_2: "with friends",
                          frage_2: "How do you go about this?",
                          antwort_2_1: "tasteful",
                          antwort_2_2: "wild",
                          coctail_1_1: CherryKissComponent,
                          coctail_1_2: TheMollyPitcherComponent,
                          coctail_2_1: LovelyMelonComponent,
                          coctail_2_2: CitrusRemedyComponent
                      },
                      // FRAGE 3
                      {
                          frage: "In which season do you like to travel?",
                          antwort_1: "summer",
                          antwort_2: "winter",
                          frage_1: "Where do you prefer to travel to?",
                          antwort_1_1: "beach",
                          antwort_1_2: "city",
                          frage_2: "Wohin reist du am liebsten?",
                          antwort_2_1: "mountains",
                          antwort_2_2: "coast",
                          coctail_1_1: ExoticRemedyComponent,
                          coctail_1_2: SweetMojitoComponent,
                          coctail_2_1: CowShoeComponent,
                          coctail_2_2: AppleADayComponent
                      },
                      // FRAGE 4
                      {
                          frage: "How would you describe your behaviour?",
                          antwort_1: "loud",
                          antwort_2: "quiet",
                          frage_1: "What do you love to do most?",
                          antwort_1_1: "dancing",
                          antwort_1_2: "conversations",
                          frage_2: "What do you love to do most?",
                          antwort_2_1: "listening",
                          antwort_2_2: "chatting",
                          coctail_1_1: RemedyOComponent,
                          coctail_1_2: RemedyIceTeaComponent,
                          coctail_2_1: RemedyNegroniComponent,
                          coctail_2_2: GingerMojitoComponent
                      },
                      // FRAGE 5
                      {
                          frage: "What taste do you like more?",
                          antwort_1: "precious spices",
                          antwort_2: "sweet loveliness",
                          frage_1: "Do you prefer something light and fruity or something bold and full of character?",
                          antwort_1_1: "fruity",
                          antwort_1_2: "strong",
                          frage_2: "Do you prefer something light and fruity or something bold and full of character?",
                          antwort_2_1: "fruity",
                          antwort_2_2: "strong",
                          coctail_1_1: MrFayComponent,
                          coctail_1_2: DarkRemedyComponent,
                          coctail_2_1: ExoticRemedyComponent,
                          coctail_2_2: RemedyNegroniComponent
                      },
                      // {
                      //     frage: "Was trinkst du morgens lieber?",
                      //     antwort_1: "Kaffee",
                      //     antwort_2: "Tee",
                      //     frage_1: "Wie trinkst du dein Getränk?",
                      //     antwort_1_1: "Schwarz",
                      //     antwort_1_2: "Mit Milch",
                      //     frage_2: "Wie trinkst du dein Getränk?",
                      //     antwort_2_1: "Schwarz",
                      //     antwort_2_2: "Fruchtig",
                      //     coctail_1_1: DarkRemedyComponent,
                      //     coctail_1_2: GingerMojitoComponent,
                      //     coctail_2_1: ElixirTonicComponent,
                      //     coctail_2_2: RemedyIceTeaComponent
                      // },
                      // FRAGE 6
                      // {
                      //     frage: "Wohin gehst du lieber aus?",
                      //     antwort_1: "Kneipe",
                      //     antwort_2: "Cocktailbar",
                      //     frage_1: "Was trinkst du dort sehr gerne?",
                      //     antwort_1_1: "Bier",
                      //     antwort_1_2: "Longdrinks",
                      //     frage_2: "Was trinkst du dort sehr gerne?",
                      //     antwort_2_1: "Starke Cocktails",
                      //     antwort_2_2: "Fruchtige Cocktails",
                      //     coctail_1_1: SpicedBeerComponent,
                      //     coctail_1_2: ElixirTonicComponent,
                      //     coctail_2_1: RemedyNegroniComponent,
                      //     coctail_2_2: MrFayComponent
                      // },
                      // // FRAGE 7
                      // {
                      //     frage: "Welche Art von Sport praktizierst du?",
                      //     antwort_1: "Bewegungssport",
                      //     antwort_2: "Kampfsport",
                      //     frage_1: "Etwas genauer?",
                      //     antwort_1_1: "Ballsport",
                      //     antwort_1_2: "Leichtathletik",
                      //     frage_2: "Etwas genauer?",
                      //     antwort_2_1: "Boxen",
                      //     antwort_2_2: "Andere Kampfkunst",
                      //     coctail_1_1: CitrusRemedyComponent,
                      //     coctail_1_2: AppleADayComponent,
                      //     coctail_2_1: RemedyNegroniComponent,
                      //     coctail_2_2: GingerMojitoComponent
                      // },
                      // // FRAGE 8
                      // {
                      //     frage: "Welche Stadt würdest du gerne besuchen? New York oder Los Angeles?",
                      //     antwort_1: "New York",
                      //     antwort_2: "Los Angeles",
                      //     frage_1: "Was reizt dich an der Stadt?",
                      //     antwort_1_1: "Das Nachtleben",
                      //     antwort_1_2: "Die Skyline",
                      //     frage_2: "Was reizt dich an der Stadt?",
                      //     antwort_2_1: "Das Wetter",
                      //     antwort_2_2: "Der Glamour",
                      //     coctail_1_1: CitrusRemedyComponent,
                      //     coctail_1_2: DarkRemedyComponent,
                      //     coctail_2_1: SweetMojitoComponent,
                      //     coctail_2_2: ExoticRemedyComponent
                      // },
                      // // FRAGE 9
                      // {
                      //     frage: "Bist du verheiratet oder unverheiratet?",
                      //     antwort_1: "Verheiratet",
                      //     antwort_2: "Unverheiratet",
                      //     frage_1: "Beschreibe mir den Status?",
                      //     antwort_1_1: "Glücklich verheiratet",
                      //     antwort_1_2: "Nur verheiratet",
                      //     frage_2: "Beschreibe mir den Status?",
                      //     antwort_2_1: "Freund/-in ",
                      //     antwort_2_2: "Single",
                      //     coctail_1_1: TheMollyPitcherComponent,
                      //     coctail_1_2: BouncersChoiceComponent,
                      //     coctail_2_1: BitterElixirComponent,
                      //     coctail_2_2: CherryKissComponent
                      // },
                      // // FRAGE 10
                      // {
                      //     frage: "Was magst du lieber? Filme oder Musik?",
                      //     antwort_1: "Filme",
                      //     antwort_2: "Musik",
                      //     frage_1: "Welche Art von Musik/Film?",
                      //     antwort_1_1: "Liebesfilm",
                      //     antwort_1_2: "Actionfilm",
                      //     frage_2: "Welche Art von Musik/Film?",
                      //     antwort_2_1: "Entspannte Musik",
                      //     antwort_2_2: "Tanzmusik",
                      //     coctail_1_1: TheMollyPitcherComponent,
                      //     coctail_1_2: GingerMojitoComponent,
                      //     coctail_2_1: SmugglersJuiceComponent,
                      //     coctail_2_2: HolyWineComponent
                      // },
                      // // FRAGE 11
                      // {
                      //     frage: "Schokolade oder Weingummi?",
                      //     antwort_1: "Schokolade",
                      //     antwort_2: "Weingummi",
                      //     frage_1: "Etwas genauer?",
                      //     antwort_1_1: "Vollmilch",
                      //     antwort_1_2: "Zartbitter",
                      //     frage_2: "Etwas genauer?",
                      //     antwort_2_1: "Fruchtgummi",
                      //     antwort_2_2: "Lakritz",
                      //     coctail_1_1: CowShoeComponent,
                      //     coctail_1_2: RemedyNegroniComponent,
                      //     coctail_2_1: LovelyMelonComponent,
                      //     coctail_2_2: SpicedBeerComponent
                      // },
                      // // FRAGE 12
                      // {
                      //     frage: "Welche Früchte bevorzugst du?",
                      //     antwort_1: "Exotische Früchte",
                      //     antwort_2: "Heimische Früchte",
                      //     frage_1: "Welche Frucht sagt dir eher zu?",
                      //     antwort_1_1: "Mango",
                      //     antwort_1_2: "Wassermelone",
                      //     frage_2: "Welche Frucht sagt dir eher zu?",
                      //     antwort_2_1: "Apfel",
                      //     antwort_2_2: "Himbeere",
                      //     coctail_1_1: MrFayComponent,
                      //     coctail_1_2: LovelyMelonComponent,
                      //     coctail_2_1: AppleADayComponent,
                      //     coctail_2_2: BitterElixirComponent
                      // },
                      // // FRAGE 13
                      // {
                      //     frage: "Was liest du lieber?",
                      //     antwort_1: "Bücher",
                      //     antwort_2: "Zeitung / Zeitschrift",
                      //     frage_1: "Welche Art von Lesestoff?",
                      //     antwort_1_1: "Krimi",
                      //     antwort_1_2: "Liebesroman",
                      //     frage_2: "Welche Art von Lesestoff?",
                      //     antwort_2_1: "Tageszeitung",
                      //     antwort_2_2: "Fachzeitschrift",
                      //     coctail_1_1: GingerMojitoComponent,
                      //     coctail_1_2: CherryKissComponent,
                      //     coctail_2_1: DarkRemedyComponent,
                      //     coctail_2_2: RemedyNegroniComponent
                      // },
                      // // FRAGE 14
                      // {
                      //     frage: "Wie hättest du bei der Prohibitions-Frage abgestimmt?",
                      //     antwort_1: "Für das Alkoholverbot",
                      //     antwort_2: "Gegen das Alkoholverbot",
                      //     frage_1: "Warum?",
                      //     antwort_1_1: "Weil das Verbot richtig ist.",
                      //     antwort_1_2: "Weil ich das Verbotene Mag.",
                      //     frage_2: "Warum?",
                      //     antwort_2_1: "Der Staat hat sich nicht einzumischen.",
                      //     antwort_2_2: "Weil an einem guten Drink nichts Verwerfliches ist.",
                      //     coctail_1_1: StillesWasserComponent,
                      //     coctail_1_2: SmugglersJuiceComponent,
                      //     coctail_2_1: TheMollyPitcherComponent,
                      //     coctail_2_2: AppleADayComponent
                      // },
                      // // FRAGE 15
                      // {
                      //     frage: "Was für Drinks machst du dir gerne zu Hause?",
                      //     antwort_1: "Einfache  Drinks",
                      //     antwort_2: "Etwas anspruchsvoller Drinks",
                      //     frage_1: "Wie soll dein Drink sein?",
                      //     antwort_1_1: "Fruchtig",
                      //     antwort_1_2: "Limonadig",
                      //     frage_2: "Wie soll dein Drink sein?",
                      //     antwort_2_1: "Stark",
                      //     antwort_2_2: "Leicht",
                      //     coctail_1_1: ExoticRemedyComponent,
                      //     coctail_1_2: CitrusRemedyComponent,
                      //     coctail_2_1: HolyWineComponent,
                      //     coctail_2_2: MrFayComponent
                      // },
                      // // FRAGE 16
                      // {
                      //     frage: "Welche meiner Kreationen magst du lieber?",
                      //     antwort_1: "Remedy Spiced Rum",
                      //     antwort_2: "Remedy Elixir",
                      //     frage_1: "Wie soll dein Drink sein?",
                      //     antwort_1_1: "Fruchtig",
                      //     antwort_1_2: "Limonadig",
                      //     frage_2: "Wie soll dein Drink sein?",
                      //     antwort_2_1: "Fruchtig",
                      //     antwort_2_2: "Limonadig",
                      //     coctail_1_1: MrFayComponent,
                      //     coctail_1_2: RemedyOComponent,
                      //     coctail_2_1: ExoticRemedyComponent,
                      //     coctail_2_2: BitterElixirComponent
                      // },
                      // // FRAGE 17
                      // {
                      //     frage: "Welche meiner Kreationen magst du lieber?",
                      //     antwort_1: "Remedy Spiced Rum",
                      //     antwort_2: "Remedy Elixir",
                      //     frage_1: "Soll dich dein Cocktail bei einem gemütlichen Abend oder einer ausgelassenen Feier begleiten?",
                      //     antwort_1_1: "Gemütlicher Abend",
                      //     antwort_1_2: "Ausgelassene Feier",
                      //     frage_2: "Soll dich dein Cocktail bei einem gemütlichen Abend oder einer ausgelassenen Feier begleiten?",
                      //     antwort_2_1: "Gemütlicher Abend",
                      //     antwort_2_2: "Ausgelassene Feier",
                      //     coctail_1_1: SweetMojitoComponent,
                      //     coctail_1_2: DarkRemedyComponent,
                      //     coctail_2_1: LovelyMelonComponent,
                      //     coctail_2_2: ElixirTonicComponent
                      // },
                      // // FRAGE 18
                      // {
                      //     frage: "Welche meiner Kreationen magst du lieber?",
                      //     antwort_1: "Remedy Spiced Rum",
                      //     antwort_2: "Remedy Elixir",
                      //     frage_1: "Ist das Wetter gerade warm oder kalt?",
                      //     antwort_1_1: "Warm",
                      //     antwort_1_2: "Kalt",
                      //     frage_2: "Ist das Wetter gerade warm oder kalt?",
                      //     antwort_2_1: "Warm",
                      //     antwort_2_2: "Kalt",
                      //     coctail_1_1: MrFayComponent,
                      //     coctail_1_2: AppleADayComponent,
                      //     coctail_2_1: RemedyIceTeaComponent,
                      //     coctail_2_2: CowShoeComponent
                      // },
                      // // FRAGE 19
                      // {
                      //     frage: "Welche meiner Kreationen magst du lieber?",
                      //     antwort_1: "Remedy Spiced Rum",
                      //     antwort_2: "Remedy Elixir",
                      //     frage_1: "Kreierst du den Drink für dich allein oder hast du mehrere Gäste zu verköstigen?",
                      //     antwort_1_1: "Für Mich",
                      //     antwort_1_2: "Für Gäste",
                      //     frage_2: "Kreierst du den Drink für dich allein oder hast du mehrere Gäste zu verköstigen?",
                      //     antwort_2_1: "Für Mich",
                      //     antwort_2_2: "Für Gäste",
                      //     coctail_1_1: SmugglersJuiceComponent,
                      //     coctail_1_2: CitrusRemedyComponent,
                      //     coctail_2_1: TheMollyPitcherComponent,
                      //     coctail_2_2: ExoticRemedyComponent
                      // },
                      // // FRAGE 20
                      // {
                      //     frage: "Welche andere Spirituose außer Rum magst du gerne?",
                      //     antwort_1: "Gin",
                      //     antwort_2: "Whisky",
                      //     frage_1: "Welche Art von Drinks trinkst du bei dieser Kategorie gerne?",
                      //     antwort_1_1: "Klassischer Drink",
                      //     antwort_1_2: "Longdrink",
                      //     frage_2: "Welche Art von Drinks trinkst du bei dieser Kategorie gerne?",
                      //     antwort_2_1: "Longdrink",
                      //     antwort_2_2: "Shots",
                      //     coctail_1_1: RemedyNegroniComponent,
                      //     coctail_1_2: ElixirTonicComponent,
                      //     coctail_2_1: DarkRemedyComponent,
                      //     coctail_2_2: SweetSourShotComponent
                      // },
                      // // FRAGE 21
                      // {
                      //     frage: "Welche meiner Kreationen magst du lieber?",
                      //     antwort_1: "Remedy Spiced Rum",
                      //     antwort_2: "Remedy Elixir",
                      //     frage_1: "Würdest du in meiner Heimat New York eher an der Upper Eastside, bei den reichen und schönen oder in Harlem, bei den kreativen Künstlern leben wollen?",
                      //     antwort_1_1: "Upper East Side",
                      //     antwort_1_2: "Harlem",
                      //     frage_2: "Würdest du in meiner Heimat New York eher an der Upper Eastside, bei den reichen und schönen oder in Harlem, bei den kreativen Künstlern leben wollen?",
                      //     antwort_2_1: "Upper East Side",
                      //     antwort_2_2: "Harlem",
                      //     coctail_1_1: MrFayComponent,
                      //     coctail_1_2: BouncersChoiceComponent,
                      //     coctail_2_1: ExoticRemedyComponent,
                      //     coctail_2_2: BitterElixirComponent
                      // },
                  ]
              }
          }
        },
        mounted(){
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});

            this.randomFrageIndex();

            this.openBox2 = window.innerWidth < this.browserWidth;

            window.addEventListener( 'resize', this.resizeWindow );

        },
        computed: {
            ...mapGetters(['getLang']),
          getCoctailComponent(){
              return this.fragen[this.getLang][this.indexFrage][`coctail_${this.antwort_1}_${this.antwort_2}`]
          }
        },
        watch:{
          step(){
              this.$refs.psContent.$el.scrollTop = 0;
              this.$refs.psContent.ps.update();
          }
        },
        methods: {
            resizeWindow(){
                this.openBox2 = window.innerWidth < this.browserWidth;
            },
          newGame(){
              this.antwort_1 = 1;
              this.antwort_2 = 1;
              this.step = 1;

              this.randomFrageIndex();

          },
          randomizeInteger(min, max) {
            if(max == null) {
                max = (min == null ? Number.MAX_SAFE_INTEGER : min);
                min = 0;
            }

            min = Math.ceil(min);  // inclusive min
            max = Math.floor(max); // exclusive max

            if(min > max - 1) {
                throw new Error("Incorrect arguments.");
            }

            return min + Math.floor((max - min + 1) * Math.random());
          },
            randomFrageIndex(){
                this.indexFrage = this.randomizeInteger(0, this.fragen[this.getLang].length - 1);
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/vars";

    #store-sprech-button{
        position: absolute;
        top: 30px;
        left: 30px;

        z-index: 5;

        width: 204px;
        height: 57px;

        background-image: url("../assets/images/cta-to-store-white.png");
        background-size: cover;

        transition: 0.3s all ease-in-out;
        transform: scale(1);

        &.english{
            background-image: url("../assets/images/cta-to-store-en-white.png");
        }
        &:hover{
            transform: scale(1);
        }
    }

    @ratioSprech: 1;
    #sprechstunde{
        position: relative;
        width: 1920px * @ratioSprech;
        height: 1047px * @ratioSprech;

        background: url("../assets/images/sprechstunde-bg.jpg") top left no-repeat;
        background-size: cover;

        &.sprechstunde_en{
            background-image: url("../assets/images/sprechstunde-bg-en.jpg");
        }
    }

    #sprechstunde-box-close, #sprechstunde-box-close2{
        position: absolute;
        top: 5px;
        right: -35px;

        width: 26px;
        height: 26px;

        background: url("../assets/images/sprech-close.png") center center no-repeat;

        cursor: pointer;

        transition: 0.2s all ease-in-out;

        &:hover{
            transform: scale(1.2);
        }
    }

    #sprechstunde-button2{
        width: 288px;
        height: 121px;

        cursor: pointer;

        .normal{
            height: 100%;
            width: 100%;

            transition: 0.2s all ease-in-out;

            background: url("../assets/images/cta-start.png") center center no-repeat;
            background-size: cover;
        }


    }

    #sprechstunde-button{
        position: absolute;
        left: 960px * @ratioSprech;
        top: 700px * @ratioSprech;

        width: 288px * @ratioSprech;
        height: 121px * @ratioSprech;

        cursor: pointer;

        transform: rotate(-12deg);

        &:hover{
            .normal{
                opacity: 0;
            }
            .hover{
                opacity: 1;
            }
        }

        .normal{
            position: absolute;
            height: 100%;
            width: 100%;

            transition: 0.2s all ease-in-out;

            background: url("../assets/images/cta-start.png") center center no-repeat;
            background-size: cover;
        }

        .hover{
            position: absolute;
            height: 100%;
            width: 100%;

            transition: 0.2s all ease-in-out;

            opacity: 0;

            background: url("../assets/images/cta-start-hover.png") center center no-repeat;
            background-size: cover;
        }
    }
    #sprechstunde-box-bg{
        z-index: 4!important;
    }
    #sprechstunde-box-bg,  #sprechstunde-box2-bg {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        z-index: 3;

        display: flex;
        justify-content: center;
        align-items: center;

        background: url("../assets/images/sprechstunde-box-bg.png") top center no-repeat;
        background-size: cover;
    }

    #sprechstunde-box, #sprechstunde-box2{
        position: relative;
        width: 676px * @ratioSprech;
        height: 952px * @ratioSprech;
        background: url("../assets/images/sprechstunde-box.jpg") top center no-repeat;
        background-size: cover;

        padding-top: 150px * @ratioSprech;
        padding-bottom: 150px * @ratioSprech;

        padding-left: 50px * @ratioSprech;
        padding-right: 50px * @ratioSprech;

        display: flex;
        justify-content: center;
        align-items: center;


    }

    .step{
        text-align: center;
        font-size: 20px;
        font-family: "Palatino-Roman";
        color: @themeColor1;
        font-weight: 500;

        margin-bottom: 15px;
    }

    .step{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #sprechstunde-box-content, #sprechstunde-box-content2 {
        padding: 0px 30px;

        height: 100%;


        .title{
            font-size: 50px * @ratioSprech;
            line-height: 1;
            font-family: "docktrinregular";
            color: @themeColor1;
            text-align: center;

            padding-bottom: 55px * @ratioSprech;
        }

        .antworten {
            padding: 0;
            margin: 0;
            list-style: none outside;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            li{
                @beforeWidth: 112px / 2;
                @beforeHeight: 54px / 2;
                @marginZWischen: 30px / 2;

                padding: (40px * @ratioSprech) 0px;
                font-size: 29px;
                color: @themeColor1;

                transform: translateX(-(@beforeWidth+@marginZWischen)/2);

                display: flex;
                align-items: center;

                &:hover{
                    cursor: pointer;
                    color: @themeColor2;
                }

                &.active{
                    color: @themeColor2;
                    &::before{
                        background: url("../assets/images/cursor-ja.png") top center no-repeat;
                        background-size: cover;
                    }
                }

                &::before{
                    display: inline-block;
                    width: @beforeWidth;
                    height: @beforeHeight;
                    margin-right: @marginZWischen;
                    content: ' ';

                }
            }
        }

        .weiter{
            margin-top: 55px * @ratioSprech;
            position: relative;
            width: 160px ;
            height: 66px;

            &:hover{
                .normal{ opacity: 0 }
                .hover{ opacity: 1 }
            }

            &.weiter-en{
                .normal{
                    background-image: url("../assets/images/cta-next.png");

                }

                .hover{

                    background-image: url("../assets/images/cta-next-hover.png");

                }
            }

            .normal, .hover{
                position: absolute;
                width: 100%;
                height:100%;

                transition: 0.3s all ease-in-out;

                cursor: pointer;
            }

            .normal{
                background: url("../assets/images/cta-weiter.png") center center no-repeat;
                background-size: cover;
            }

            .hover{
                opacity: 0;
                background: url("../assets/images/cta-weiter-hover.png") center center no-repeat;
                background-size: cover;
            }
        }

        .new-game-button{
            margin-top: 15px;
            position: relative;
            width: 221px ;
            height: 66px;

            &:hover{
                .normal{ opacity: 0 !important }
                .hover{ opacity: 1 !important }
            }

            &.ngb-en{
                .normal{
                    background-image: url("../assets/images/cta-new-start.png");

                }

                .hover{

                    background-image: url("../assets/images/cta-new-start-hover.png");

                }
            }

            .normal, .hover{
                position: absolute;
                width: 100%;
                height:100%;

                transition: 0.3s all ease-in-out;

                cursor: pointer;
            }

            .normal{
                background: url("../assets/images/cta-neu-starten.png") center center no-repeat;
                background-size: cover;
            }

            .hover{
                opacity: 0;
                background: url("../assets/images/cta-neu-starten-hover.png") center center no-repeat;
                background-size: cover;
            }
        }
    }

</style>
