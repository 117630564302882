<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'DarkRemedyComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Dark Remedy',
                        imgSrc: '../../../coctails/darkremedy.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 2 cl Limettensaft <br>  Ginger Beer',
                        zubereitung: 'Remedy Spiced Rum und Limettensaft in ein Highballglas geben und mit Ginger Beer auffüllen.',
                        desc: 'Einer meiner absoluten Favoriten. Dieser Drink hat eine geschmackvolle Balance, bestehend aus der Süße Remedys und der kräftigen Würze der Ingwerlimonade, die einfach jeden begeistert.'
                    },
                    en: {
                        title: 'Dark Remedy',
                        imgSrc: '../../../coctails/darkremedy.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 2 cl lime juice <br>  Ginger beer',
                        zubereitung: 'Pour the Remedy Spiced Rum and lime juice into a highball glass. Top it up with ginger beer.',
                        desc: 'One of my all-time favourites! This drink wows with a really balanced taste. Nobody can resist its perfect combination of sweetness from the Remedy and spice from the ginger lemonade.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
