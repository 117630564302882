<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'SmugglersJuiceComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Smuggler’s Juice',
                        imgSrc: '../../../coctails/smugglerjuice.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 3 cl Ananassaft <br> 8 cl Cranberrysaft',
                        zubereitung: 'Alle Zutaten in ein Glas (Tumbler) mit Eiswürfeln geben und gut verrühren.',
                        desc: 'Alkohol zu schmuggeln ist ein gefährliches und nervenaufreibendes Unterfangen. Da die geringen Mengen, die ich legal für medizinische Zwecke erhielt, bei weitem nicht ausreichten, öffnete ich so manches Mal nachts um 3 die Türen meiner Apotheke und nahm nervlich angespannte „Lieferanten“ in Empfang. Zur Beruhigung und Stärkung gab es dann erstmal eine Runde Smuggler’s Juice.'
                    },
                    en: {
                        title: 'Smuggler’s Juice',
                        imgSrc: '../../../coctails/smugglerjuice.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 3 cl pineapple juice <br> 8 cl cranberry juice',
                        zubereitung: 'Pour all ingredients intoa tumbler glass filled withice cubes. Stir well.',
                        desc: 'Smuggling alcohol is a dangerous task that can fray one’snerves. I know what I’m talking about: As the small amountsof alcohol that I obtained legally for medicinal purposes werenot enough, I often found myself opening the door of mypharmacy at three o’clock in the morning to usher a tense"deliveryman" inside. To help the men calm down somewhatand replenish their strength, I used to offer them a round ofSmuggler’s Juice.'
                    }
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
