<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'AppleADayComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Apple A Day',
                        imgSrc: '../../../coctails/appleaday.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 14 cl apple juice',
                        zubereitung: 'Remedy Spiced Rum mit warmem oder auch kaltem Apfelsaft in ein Glas geben und gut verrühren. Bei Bedarf mit etwas Zimt bestäuben.',
                        desc: 'An apple a day keeps the doctor away. An warmen Tagen mit reichlich Eis, an kalten, verregneten Tagen schön aufgewärmt mit einer Prise Zimt, macht mich dieser Drink stets munter.',
                    },
                    en: {
                        title: 'Apple A Day',
                        imgSrc: '../../../coctails/appleaday.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 14 cl apple juice',
                        zubereitung: 'Pour the Remedy Spiced Rum into a glass with warm or cold apple juice, and stir well. Optionally, dust the drink with a pinch of cinnamon powder.',
                        desc: '"An apple a day keeps the doctor away." Especially on cold, rainy days, warm apple juice with a splash of Remedy Spiced Rum and a dash of cinnamon is what helps me keep my spirits up.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
