<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'SweetMojitoComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Sweet Mojito',
                        imgSrc: '../../../coctails/sweetmojito.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl Limettensaft (Saft einer halben Limette) <br>Limettenwürfel <br> 1Teelöffel Rohrzucker <br> Ginger Ale <br>Minzeblätter',
                        zubereitung: 'Remedy Spiced Rum, Limettensaft, Rohrzucker, Limettenwürfel und Minzeblätter in ein Highballglas geben und mit einem Stößel etwas zerdrücken. Eiswürfel oder Crushed Ice hinzugeben und mit Ginger Ale auffüllen.',
                        desc: 'Eine Abwandlung des klassischen Mojitos. Dieser wiederum soll bereits im 16. Jahrhundert erfunden und konsumiert worden sein, um Übelkeit und Magenbeschwerden zu lindern. Die frischen Öle der Minze, gepaart mit der fruchtigen Säure der Limette und den lieblich würzigen Noten des Rums und Ginger Ales sind eine wahre Wohltat an lauwarmen Sommerabenden.'
                    },
                    en: {
                        title: 'Sweet Mojito',
                        imgSrc: '../../../coctails/sweetmojito.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl lime juice (the juice of half a lime) <br>Lime cubes <br> 1 teaspoon cane sugar <br> Ginger ale <br>Mint leaves',
                        zubereitung: 'Pour the Remedy Spiced Rum, lime juice, cane sugar, lime cubes and mint leaves into a highball glass and usea pestle to gently crush all ingredients. Add ice cubes or crushed ice and fill the glass with ginger ale.',
                        desc: 'Rumour has it that a variation of today’s mojito had already been created and consumed in the 16th century, presumably to alleviate nausea and stomach complaints. The fresh oils of the mint leaves, combined with the fruity acids of the lime as well as the lovely, spicy taste of the rum and the ginger ale, are a delight to enjoy on a warm summer evening.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
