<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'CherryKissComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Cherry Kiss',
                        imgSrc: '../../../coctails/cherrykiss.jpg',
                        zutaten: '6 cl Remedy Spiced Rum <br> 2 cl Limettensaft (Saft einer halben Limette) <br>  Kirschsaft',
                        zubereitung: 'Remedy Spiced Rum, einen Spritzer Limettensaft und Eiswürfel in einen Tumbler geben. Das Glas mit Kirschsaft auffüllen.',
                        desc: 'Der Filmschauspieler William Mack wurde regelmäßig mit einer außerordentlich hübschen, blonden Kollegin namens Julia Mayer in den verschiedenen geheimen Clubs New Yorks gesehen. Dort vergnügten sie sich nächtelang, wobei er stets Bourbon Whisky und sie den beliebten Remedy Cherry Kisstrank. Mayer musste jedoch in ihre Heimat Deutschland zurückkehren und verließ den aufstrebenden Star. Diese Trennung schmerzte ihn so sehr, dass man ihn noch Jahre später in den Bars sitzen sah, wo er nicht länger mit Whisky, sondern bei einem Glas Remedy Cherry Kiss in Erinnerung schwelgte.'
                    },
                    en: {
                        title: 'Cherry Kiss',
                        imgSrc: '../../../coctails/cherrykiss.jpg',
                        zutaten: '8 cl Remedy Spiced Rum <br> 6 cl Cola <br>  Stout beer',
                        zubereitung: 'Pour the Remedy Spiced Rum, a splash of lime juice and crushed ice into a longdrink glass. Fill the glass with cherry juice and garnish the drink with two fresh cherries.',
                        desc: 'Actor William Mack was frequently spotted at various secret clubs in New York with an extraordinarily beautiful, blonde colleague named Julia Mayer. These two used to party together for nights on end, and while he always ordered his cherished Bourbon Whiskey, she would always drink the popular Cherry Kiss. However, Mayer had to leave the US for her home country Germany, and that was the end of this star-crossed affair. Mack took the separation to heart. It pained him so much that he could still be seen sitting in those same bars years later, alone, drowning his sorrows and revelling in memories by drinking not his Bourbon but the Cherry Kiss that his former sweetheart had preferred.'
                    },
        },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
