<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'BitterElixirComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Bitter Elixir',
                        imgSrc: '../../../coctails/bitterelixir.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 2 cl Limettensaft <br>  3-4 Himbeeren <br> Bitter Lemon',
                        zubereitung: 'Remedy Elixir, Limettensaftin ein Highballglas geben. Himbeeren dazu geben und mit einem Stößel zerdrücken .In das Glas Eiswürfel hin-zugeben und mit Bitter Lemon auffüllen.',
                        desc: 'Dieser wunderbare Drink kombiniert perfekt die Süße desRemedy Elixirs mit einer angenehmen Frische der Himbeerenund einer leichten Bitternote der Limonade. Man kann diesewunderbare Kreation auch mit Erdbeeren, Blaubeeren oderBrombeeren abwandeln. Dieser Drink war im Übrigen einabsoluter Kassenschlager im legendären Savoy Ballroom.Zu ausgelassener Jazz- und Swing-Musik war dieser Drink einelementarer Bestandteil für wilde, unvergessliche Partys.'
                    },
                    en: {
                        title: 'Bitter Elixir',
                        imgSrc: '../../../coctails/bitterelixir.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 2 cl lime juice <br>  3-4 raspberries <br> Bitter lemon',
                        zubereitung: 'Pour the Remedy Elixir and lime juice into a highball glass. Add the raspberries and crush them with a pestle.Then fill the glass with ice cubes and bitter lemon.',
                        desc: 'This wonderful drink perfectly combines the sweetness ofRemedy with the pleasant freshness of raspberries and the slight bitterness of lemonade. If you want, you can create variations of the recipe by using strawberries, blueberries or blackberries. This drink, by the way, used to be a total hit in the legendary Savoy Ballroom. Savoured alongside exuberant jazz and swing music, the drink was elementary for wild, unforgettable parties.'
                    }
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
