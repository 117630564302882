<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'SpicedBeerComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Spiced Beer',
                        imgSrc: '../../../coctails/spicedbeer.jpg',
                        zutaten: '8 cl Remedy Spiced Rum <br> 6 cl Cola <br>  Schwarzbier/ Stout',
                        zubereitung: 'Remedy Spiced Rum mit Cola in ein Pint Glas geben und dieses mit Schwarzbier / Stout auffüllen',
                        desc: 'Diese ausgefallene Erfindung stammt aus Bay Ridge, einem Viertel, das hauptsächlich von irischstämmigen Menschen bewohnt wird. Hier gab es den gut versteckten, kleinen Irish Pub „Earl & Tobson.“ Eines späten Abends, bei einer sehr ausgelassen Party, vermischte ein Gast den Rest seines Remedy Colas mit einem ebenfalls vor sich stehenden Stout Beer. Das Ergebnis ist phänomenal und wie so oft ein glücklicher Zufall. Zufälle sind unvorhergesehene Ereignisse, die einen Sinn haben.'
                    },
                    en: {
                        title: 'Spiced Beer',
                        imgSrc: '../../../coctails/spicedbeer.jpg',
                        zutaten: '8 cl Remedy Spiced Rum <br> 6 cl Cola <br>  Stout beer',
                        zubereitung: 'Pour the Remedy Spiced Rum and the coke into a pint glass, then fill the glass with stout beer.',
                        desc: 'This weird invention hails from Bay Ridge, a suburb mostly inhabited by people of Irish descent. This borough also housed the small, secret Irish pub Earl & Tobson. One night, during an especially excessive party, a guest mixed the rest of his Remedy Coke with the remainders of a stout beer that was also in front of him. The result of this fortuitous occasion was phenomenal. We love such unexpected strikes of fortune, especially if those incidents happen to make a lot of sense.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
