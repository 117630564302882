<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'MrFayComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Mr. Fay',
                        imgSrc: '../../../coctails/mrfay.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl lime juice (the juice of half a lime)<br>  Mango juice <br> Mint leaves ',
                        zubereitung: 'Remedy Spiced Rum, Limettensaft und Minzeblätter in ein Highballglas geben und mit einem Stößel etwas zerdrücken. Den Mangosaft hinzugeben. Das Glas mit Crushed Ice füllen und alles gut ca. 15 Sekunden mit einem Löffel verrühren, sodass ein wenig Schmelzwasser entsteht.',
                        desc: 'Larry Fay war in New York einer der bekanntesten Schmuggler von Alkohol, ein sogenannter „Rumrunner.“ Mit dem „Casa Blanca“ eröffnete er einen der beliebtesten Clubs in New York, in dem dieser hier präsentierte Drink mit Genuss getrunken wurde. Tragischerweise wurde Fay am 1. Januar 1933 in seinem Club von einem Türsteher erschossen, als ein Streit über dessen Bezahlung eskalierte. Ihm zu Ehren sollt ihr diesen tollen, frischen Drink genießen. Cheers to you Mr. Fay.'
                    },
                    en: {
                        title: 'Mr. Fay',
                        imgSrc: '../../../coctails/mrfay.jpg',
                        zutaten: '5 cl Remedy Spiced Rum <br> 2 cl Limettensaft (Saft einer halben Limette) <br>  Mangosaft <br> Minzeblätter ',
                        zubereitung: 'Pour the Remedy Spiced Rum, lime juice and mint leaves into a tumbler and use a pestle to gently crush all ingredients. Add the mango juice. Fill the glass with crushed ice and stir thoroughly with a spoon for roughly 15 seconds so that part of the ice melts.',
                        desc: 'Larry Fay was one of New York’s most notorious alcohol smugglers, one of the so-called "rum runners". He went on to open one of New York’s most popular clubs, the Casa Blanca. People frequenting it loved this drink. Tragically, on January 1st in 1933, Larry Fay was fatally shot by one of his own bouncers at the door of his club when a fight over payment escalated. Enjoy this drink in his memory. Cheers to you, Mr. Fay!'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
