<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'CowShoeComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'The Cow Shoe',
                        imgSrc: '../../../coctails/thecowshoe.jpg',
                        zutaten: '10 cl Remedy Elixir <br> 200 ml Milch <br>  Honig <br> Zimt',
                        zubereitung: 'Milch in einem Topf erwärmen, 1-2 Teelöffel Honig hinzugeben und gut verrühren, sodass dieser sich auflöst. Die heiße Milch in ein Glas oder Tasse geben. Remedy Elixir und etwas Zimt dazugeben.',
                        desc: 'Die Polizei und die sogenannten Prohibitionsagenten waren stets auf der Suche nach Alkoholschmugglern. Dabei wurden sogar die stadtnahen Wälder, Felder und Küsten nach auffälligen Spuren untersucht. Einige Schmuggler wurden dabei erfinderisch und klemmten sich spezielle Sohlen beziehungsweise Absätze unter die Schuhe, damit ihre Fußspuren denen von Kühen, Rehen oder anderen Tieren glichen. Dadurch blieben zahlreiche Schmuggelrouten unentdeckt. Den erfinderischen Männern widmen wir diese liebliche Kreation.'
                    },
                    en: {
                        title: 'The Cow Shoe',
                        imgSrc: '../../../coctails/thecowshoe.jpg',
                        zutaten: '10 cl Remedy Elixir <br> 200 ml milk <br>  Honey <br> Cinnamon',
                        zubereitung: 'Warm the milk in a pot, add 1 to 2 teaspoons of honey, and stir well until the honey has dissolved. Pour the hot milk into a glass or cup. Add the Remedy Elixir and a pinch of cinnamon powder.',
                        desc: 'The police, as well as the agents of the Prohibition Unit, were always on the look-out for alcohol smugglers. They even searched the forests bordering the city, the fields and the coast for suspicious clues. That caused some smugglers to become inventive and affix special soles or rather heels to the undersides of their shoes. These made their footprints look like animal prints (cows, deer or other animals). That, in turn, prevented the smugglers from being followed and caught. We’ve dedicated this aptly named, wondrous drink to these brave, creative souls.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
