<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'CitrusRemedyComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Citrus Remedy',
                        imgSrc: '../../../coctails/citrusremedy.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 2 cl Zitrone (Saft einer halben Zitrone)<br> Zitronenlimonade',
                        zubereitung: 'Remedy Spiced Rum, Zitronensaft in ein Highballglas geben. Das Glas mit Eiswürfeln füllen und die Zitronenlimonade dazugeben.',
                        desc: 'So einfach und doch so gut. Gerade bei den Jungen, Wilden erfreut sich dieser Drink unglaublicher Beliebtheit. Lasst Sie feiern, denn im Alter bereut man vor allem die Sünden, die man nicht begangen hat.'
                    },
                    en: {
                        title: 'Citrus Remedy',
                        imgSrc: '../../../coctails/citrusremedy.jpg',
                        zutaten: '4 cl Remedy Spiced Rum <br> 2 cl lemon juice (the juice of half a lemon)<br> Lemonade',
                        zubereitung: 'Pour the Remedy SpicedRum and the lemon juice into a highball glass. Fill the glass with ice cubes, then add lemonade.',
                        desc: 'So simple and yet so delicious. Especially the young, rebellious ones enjoy this drink enormously. Don’t begrudge them their parties, for it is only when one is old that one regrets all the sins not committed while still young.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
