<template>
    <div id="about">
        <div id="about-box">
            <div id="about-close" @click="closeBox"></div>
            <div id="about-box-top"></div>
            <div id="about-box-bottom"></div>

            <div v-if="islogged" id="logout" @click="logout">{{ getLang === 'de' ? "Abmelden" : "Logout" }}</div>

            <perfect-scrollbar id="about-box-content" ref="scrollbar-text">
                <div class="col-12 about-box-title text-center">Downloads</div>
                <div v-if="!islogged" class="col-12">
                    <div class="row justify-content-center">
                        <div class="col-12 col-xl-6" style="margin-top: 15px;">
                            <input v-model="login" type="text" name="login" :placeholder="getLang === 'de' ? 'Benutzername' : 'Login'">
                            <input v-model="password" type="password" name="password"  :placeholder="getLang === 'de' ? 'Passwort' : 'Password'">
                            <div id="login-button" :class="{ 'english': getLang === 'en'}" @click="loginFunction"></div>
                            <div v-if="error && (login.length === 0 || password.length === 0 || res_error )" class="errors col-12">
                                <div class="row">
                                    <div class="col-12"><strong>{{ getLang === 'de' ? "FEHLER:" : "ERROR:"}}</strong></div>
                                    <div class="col-12" v-if="login.length === 0">
                                        <span v-if="getLang === 'de'">- Das Feld „Benutzername” ist erforderlich.</span>
                                        <span v-if="getLang === 'en'">- Login field is required.</span>
                                    </div>
                                    <div class="col-12" v-if="password.length === 0">
                                        <span v-if="getLang === 'de'">- Das Feld „Passwort” ist erforderlich.</span>
                                        <span v-if="getLang === 'en'">- Password field is required.</span>
                                    </div>
                                    <div class="col-12" v-if="res_error">
                                        <span v-if="getLang === 'de'">- wrong login or password</span>
                                        <span v-if="getLang === 'en'">- wrong login or password</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="islogged" class="col-12 abc-text " style="">

                    <DownloadBoxComponent
                        :items="booklet"
                        :itemClass="bookletClass"
                        :title="'Booklet'"
                    >
                    </DownloadBoxComponent>

                    <DownloadBoxComponent
                        :items="logos"
                        :itemClass="logoClass"
                        :title="'Logo'"
                    >
                    </DownloadBoxComponent>

                    <DownloadBoxComponent
                            :items="productPictures"
                            :itemClass="productPicturesClass"
                            :title="'Product Pictures'"
                    >
                    </DownloadBoxComponent>

                    <DownloadBoxComponent
                            :items="brandManual"
                            :itemClass="brandManualClass"
                            :title="'Brand Manual'"
                    >
                    </DownloadBoxComponent>

                    <DownloadBoxComponent
                            :items="rest"
                            :itemClass="restClass"
                            :title="'rest'"
                    >
                    </DownloadBoxComponent>

                </div>

            </perfect-scrollbar>

        </div>
    </div>
</template>

<script>
    import DownloadBoxComponent from "../components/DownloadBoxComponent";
    import { mapGetters } from "vuex"
    import { required } from 'vuelidate/lib/validators';

    export default {
        name: "DownloadsView",
        data(){
          return{
              error: false,
              res_error: false,
              login: '',
              password: '',
              islogged: false,
              bookletClass: 'col-12 col-sm-6 col-lg-4',
              booklet: [
                  {
                      'url': '/downloads-files/Remedy_Booklet_deutsch_A6_Ansicht.pdf',
                      'urlTitle': 'Remedy Booklet Deutsch',
                      'title': 'Remedy Booklet Deutsch',
                      'icon': 'pdf',
                      'size': '4.1MB'
                  },{
                      'url': '/downloads-files/Remedy_Booklet_english_A6_Ansicht.pdf',
                      'urlTitle': 'Remedy Booklet English',
                      'title': 'Remedy Booklet English',
                      'icon': 'pdf',
                      'size': '8.7MB'
                  }
              ],
              brandManualClass: 'col-12 col-sm-6 col-lg-4',
              brandManual: [
                  {
                      'url': '/downloads-files/Remedy_Brand_Manual.pdf',
                      'urlTitle': 'Remedy Brand Manual',
                      'title': 'Remedy Brand Manual',
                      'icon': 'pdf',
                      'size': '11.5MB'
                  }
                  //,{
                  //     'url': '/downloads-files/Remedy_Spiced_Brand_Manual.pdf',
                  //     'urlTitle': 'Remedy Spiced Brand Manual',
                  //     'title': 'Remedy Spiced Brand Manual',
                  //     'icon': 'pdf',
                  //     'size': '22.5MB'
                  // },{
                  //     'url': '/downloads-files/Remedy_Pineapple_Info.pdf',
                  //     'urlTitle': 'Remedy Pineapple Info',
                  //     'title': 'Remedy Pineapple Info',
                  //     'icon': 'pdf',
                  //     'size': '3.62MB'
                  // }
              ],
              logoClass: 'col-12 col-sm-6 col-lg-3',
              logos: [
                  {
                      'url': '/downloads-files/Remedy.zip',
                      'urlTitle': 'Remedy Logo',
                      'title': 'Remedy',
                      'icon': 'zip',
                      'size': '2.06MB'
                  },{
                      'url': '/downloads-files/RemedyRum.zip',
                      'urlTitle': 'Remedy Rum Logo',
                      'title': 'Remedy Rum',
                      'icon': 'zip',
                      'size': '8.43MB'
                  },{
                      'url': '/downloads-files/Elixir.zip',
                      'urlTitle': 'Elixir Logo',
                      'title': 'Elixir',
                      'icon': 'zip',
                      'size': '7.20MB'
                  },{
                      'url': '/downloads-files/SpicedRum.zip',
                      'urlTitle': 'Spiced Rum Logo',
                      'title': 'Spiced Rum',
                      'icon': 'zip',
                      'size': '17.30MB'
                  },{
                  'url': '/downloads-files/Logo_Pineapple.zip',
                  'urlTitle': 'Pineapple Logo',
                  'title': 'Pineapple',
                  'icon': 'zip',
                  'size': '24.2MB'
                }
              ],
              productPicturesClass: 'col-12 col-sm-6 col-lg-3',
              productPictures: [
                  {
                      'url': '/downloads-files/Remedy_Elixir_Flasche_700ml_highRes.jpg',
                      'urlTitle': 'Elixir Flasche 700ml',
                      'title': 'Elixir Flasche 700ml',
                      'icon': 'jpg',
                      'size': '6.3MB'
                  },{
                      'url': '/downloads-files/Remedy_Elixir_Flasche_700ml_highRes.png',
                      'urlTitle': 'Elixir Flasche 700ml',
                      'title': 'Elixir Flasche 700ml',
                      'icon': 'png',
                      'size': '13.4MB'
                  },{
                      'url': '/downloads-files/Remedy_Flasche_700ml_highRes.jpg',
                      'urlTitle': 'Spiced Rum Flasche 700ml',
                      'title': 'Spiced Rum Flasche 700ml',
                      'icon': 'jpg',
                      'size': '5.56MB'
                  },{
                      'url': '/downloads-files/Remedy_Flasche_700ml_highRes.png',
                      'urlTitle': 'Spiced Rum Flasche 700ml',
                      'title': 'Spiced Rum Flasche 700ml',
                      'icon': 'png',
                      'size': '10.9MB'
                  },{
                      'url': '/downloads-files/Remedy_Spiced_Flasche_700ml_Pfad_highRes.jpg',
                      'urlTitle': 'SpicedFlasche 700ml',
                      'title': 'Spiced Flasche 700ml',
                      'icon': 'jpg',
                      'size': '5.8MB'
                  },{
                      'url': '/downloads-files/Remedy_Spiced_Flasche_700ml_Pfad_highRes.png',
                      'urlTitle': 'Spiced Flasche 700ml',
                      'title': 'Spiced Flasche 700ml',
                      'icon': 'png',
                      'size': '47.8MB'
                  },{
                      'url': '/downloads-files/Remedy_Pineapple_Flasche_700ml_highRes',
                      'urlTitle': 'Pineapple Rum Flasche 700ml',
                      'title': 'Pineapple Flasche 700ml',
                      'icon': 'jpg',
                      'size': '5,77MB'
                  },{
                      'url': '/downloads-files/Remedy_Pineapple_Flasche_700ml_highRes',
                      'urlTitle': 'Pineapple Rum Flasche 700ml',
                      'title': 'Pineapple Flasche 700ml',
                      'icon': 'png',
                      'size': '11,79MB'
                  }
              ],
              restClass: 'col-12 col-sm-6 col-lg-4',
              rest: [
                  {
                      'url': '/downloads-files/Designelements.zip',
                      'urlTitle': 'Designelements',
                      'title': 'Designelements',
                      'icon': 'zip',
                      'size': '377MB'
                  },{
                      'url': '/downloads-files/Spiced_Rum_Bilder.zip',
                      'urlTitle': 'Spiced Rum Bilder',
                      'title': 'Spiced Rum Bilder',
                      'icon': 'zip',
                      'size': '63,4MB'
                  },{
                      'url': '/downloads-files/pineapple-bilder.zip',
                      'urlTitle': 'Pineapple Rum Bilder',
                      'title': 'Pineapple Rum Bilder',
                      'icon': 'zip',
                      'size': '113MB'
                  },{
                      'url': '/downloads-files/Fonts.zip',
                      'urlTitle': 'Fonts',
                      'title': 'Fonts',
                      'icon': 'zip',
                      'size': '2.2MB'
                  },{
                      'url': '/downloads-files/Remedy_Deck_ppt.zip',
                      'urlTitle': 'Presentation Deck',
                      'title': 'Presentation Deck',
                      'icon': 'zip',
                      'size': '10.8MB'
                  }
              ]
          }
        },
        metaInfo: {
            title: 'Downloads',
        },
        computed: {
            ...mapGetters(['getLang']),
        },
        components: {
            DownloadBoxComponent
        },
        mounted() {
            this.$store.dispatch('makeLoaderInactive')
                .then( () => {});

            if( localStorage.getItem('logged') === 'true'){
                this.islogged = true;
            }
        },
        methods: {
            closeBox(){
                this.$router.push({ name: ( this.$store.getters.getLang === 'de' ? 'Store' : 'StoreENG' ) } );
            },
            logout(){
                this.islogged = false;
                localStorage.removeItem('logged');
            },
            loginFunction(){
                if( this.login === '' || this.password === '' ){
                    this.error = true;
                    this.res_error = false;
                }else{
                    this.error = false;
                    this.res_error = false;

                    const params = new URLSearchParams();
                    params.append('login', this.login);
                    params.append('password', this.password );

                    this.axios.post( 'auth', params )
                        .then( (  ) => {
                            this.error = false;
                            this.islogged = true;

                            this.login = '';
                            this.password = '';
                            localStorage.setItem('logged', 'true' );
                        })
                        .catch( () => {
                            this.error = true;
                            this.res_error = true;
                            this.islogged = false;
                        })
                }
            }
        },
        beforeRouteLeave(to, from, next){
            this.$store.dispatch('makeLoaderActive')
                .then( () => next() );
        },
        validations: {
            login:{
                required,
            },
            password:{
                required,
            },
        }
    }
</script>

<style lang="less" scoped>
    @import "../assets/less/vars";

    #logout{
        position: absolute;
        top: 65px;
        left: 50%;
        z-index: 2;

        transform: translateX(-50%);

        font-size: 18px;

        cursor: pointer;

        transition: 0.2s all ease-in-out;
        &:hover{
            color: red;
        }
    }

    input, textarea{
        width: 100%;
        padding: 15px;
        margin-top: -2px;

        background: none;

        font-family: "Palatino-Roman";
        font-size: 16px;
        color: @themeColor2;

        border: 4px solid @themeColor1;
    }

    #login-button{
        width: 205px;
        height: 57px;

        margin-top: 50px;

        background: url("../assets/images/cta-login.png") center center no-repeat;

        transition: 0.3s all ease-in-out;

        &.english{
            background-image: url("../assets/images/cta-login_en.png");
        }
        &:hover{
            cursor: pointer;
            transform: scale(1.05);
        }
    }

    .errors{
        margin-top: 25px;
        font-size: 16px;
    }
</style>
