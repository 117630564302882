<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'LovelyMelonComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'Lovely Melon',
                        imgSrc: '../../../coctails/lovelymelon.jpg',
                        zutaten: '6 cl Remedy Elixir <br> 2 cl Limettensaft <br>  3-4 Wassermelonenstücke <br> 1 Teelöffel Rohrzucker',
                        zubereitung: 'Alle Zutaten in ein Glas geben. Die Wassermelonenstücke mit einem Stößel komplett zerdrücken, sodass reichlich Saft austritt. Das Glas anschließend randvoll mit Crushed Ice füllen und alles gut verrühren.',
                        desc: 'Welch süße Sünde. Gerade zur Frühlings- und Sommerzeit können sowohl die Damen als auch die Herren dieser fantastischen Kreation kaum widerstehen. Einer der beliebtesten Drinks in der berühmten New Yorker Flüsterkneipe Connie’s Inn, in der viele junge Menschen sich zum Tanzen und Feiern verabredeten.'
                    },
                    en: {
                        title: 'Lovely Melon',
                        imgSrc: '../../../coctails/lovelymelon.jpg',
                        zutaten: '6 cl Remedy Elixir <br> 2 cl lime juice <br>  3-4 watermelon pieces <br> 1 teaspoon cane sugar',
                        zubereitung: 'Pour all ingredients into a glass. Thoroughly crush the watermelon pieces with a pestle. Then fill the glass to the rim with crushed ice and stir everything well.',
                        desc: 'Such a sweet sin… Especially in spring and summer, the ladies as well as the gents find themselves utterly unable to resist this fascinating temptation. This used to be one of the most popular drinks in New York’s famous speakeasy Connie’s Inn, where many youngsters met to dance and party.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
