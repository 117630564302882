<template>
    <CoctailComponent :coctailArray="coctail"></CoctailComponent>
</template>

<script>
    import CoctailComponent from "./CoctailComponent";

    export default {
        name: 'TheMollyPitcherComponent',
        data(){
            return{
                coctail: {
                    de: {
                        title: 'The Molly Pitcher',
                        imgSrc: '../../../coctails/themollypitcher.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 3 cl Grapefruitsaft <br>  10 cl Cranberrysaft',
                        zubereitung: 'Remedy Elixir, Grapefruit und Cranberrysaft in ein Glas geben. Das Glas mit Eiswürfeln oder Crushed Ice füllen und gut umrühren.',
                        desc: 'Der „Molly Pitcher Club“ war eine Anti-Prohibitions Organisation, welche von Louise Gross im Jahr 1922 gegründet wurde. Sie argumentierten, dass es illegal sei, dass sich der Staat in persönliche und private Dinge einmischt und z.B. den Alkoholgenuss verbietet. Einige der Damen, die zu dieser Organisation gehörten, begrüßte ich regelmäßig in meiner Apotheke. Sie erzählten mir von ihrem Lieblingsdrink, den sie sich mit Remedy Spiced Rum samstagabends gerne zubereiteten. Die Damen wussten wahrlich, was gut und richtig ist.'
                    },
                    en: {
                        title: 'The Molly Pitcher',
                        imgSrc: '../../../coctails/themollypitcher.jpg',
                        zutaten: '5 cl Remedy Elixir <br> 3 cl grapefruit juice <br>  10 cl cranberry juice',
                        zubereitung: 'Pour the Remedy Elixir, grapefruit juice and cranberry juice into a glass. Top up with ice cubes or crushed ice and stir well.',
                        desc: 'The Molly Pitcher Club was an anti-Prohibition organization, founded by Louise Gross in 1922. She and her supporters argued that it was illegal for the government to interfer ein people’s personal and private life by prohibiting the consumption of alcohol. Some of the ladies belonging to this organization frequented my pharmacy. They shared the recipes of their favourite drinks with me, which they enjoyed mixing with Remedy Elixir on Saturday evenings. I swear, these ladies really knew what mattered, and how to enjoy life.'
                    },
                },
            }
        },
        components:{
            CoctailComponent
        }
    }
</script>
