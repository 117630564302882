<template>
    <div class="row download-row justify-content-center">
        <div class="col-12"><h3>{{ title }}</h3></div>
        <div :class="itemClass" v-for="item in items" :key="item.title">
            <a class="download-box" :href="item.url" :title="item.urlTitle" download>
                <img v-if="item.icon === 'zip'" src="../assets/images/zip-icon.png" :alt="item.urlTitle">
                <img v-if="item.icon === 'pdf'" src="../assets/images/pdf-icon.png" :alt="item.urlTitle">
                <img v-if="item.icon === 'png'" src="../assets/images/png-icon.png" :alt="item.urlTitle">
                <img v-if="item.icon === 'jpg'" src="../assets/images/jpg-icon.png" :alt="item.urlTitle">
                <span>{{ item.title }} <br><small>({{ item.size }})</small></span>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DownloadBoxComponent',
        props: ['items', 'itemClass', 'title']
    }
</script>

<style lang="less" >
    @import "../assets/less/vars";
    h3{
        text-align: center;

        font-family: 'docktrinregular';
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 0;
        margin-top: 0;

        color: @themeColor2;
    }

    .download-row{
        padding-top: 20px;
        padding-bottom: 20px;

        &:first-child{
            padding-top: 0;
        }
    }

    .download-box{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        margin-top: 15px;
        margin-bottom: 15px;

        a{
            transition: 0.3s all ease-in-out;
            &:hover{
                color: @themeColor2;
            }
        }

        span{
            margin-top: 15px;
            text-align: center;
        }
    }
</style>

